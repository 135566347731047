<template>
    <v-card flat tile>
        <SwitchCategoryDialog :show="showSwitchDialog" :keywords="selectedRows" @close="showSwitchDialog = false" @catUpdated="catUpdated"></SwitchCategoryDialog>

        <DeleteDialog :deleteDialog="deleteDialog" :selectedDeleteItem="''" :deleteLabel="deleteLabel" :loading="false" @delete="deleteKeywords" @close="deleteDialog = false"></DeleteDialog>

        <v-slide-x-reverse-transition hide-on-leave>
            <div style="position: relative" v-if="selectedRows.length">
                <div style="position: absolute; z-index: 5; top: -38px; right: 16px">
                    <v-chip outlined small class="mr-2 pl-4 pr-1">
                        {{ selectedRows.length }} SELECTED
                        <v-btn class="mx-1" x-small icon @click="selectedRows = []"><v-icon x-small>mdi-close</v-icon></v-btn>
                    </v-chip>
                    <v-divider vertical></v-divider>
                    <v-btn elevation="0" :disabled="$store.state.monitoring_suite_group.id == 2" color="primary" outlined rounded small class="mr-2" @click="showSwitchDialog = true">
                        <v-icon class="mr-1" small>mdi-swap-horizontal</v-icon>category</v-btn
                    >
                    <v-btn elevation="0" :disabled="$store.state.monitoring_suite_group.id == 2" outlined rounded color="error" small @click="deleteDialog = true">
                        <v-icon class="mr-1" small>mdi-delete</v-icon> delete</v-btn
                    >
                </div>
            </div>
        </v-slide-x-reverse-transition>

        <OpenSerpDialog :task_id="selectedItem.task_id"></OpenSerpDialog>
        <v-slide-x-reverse-transition hide-on-leave>
            <div style="position: relative" v-if="!selectedRows.length">
                <div style="position: absolute; z-index: 5; top: -38px; right: 16px">
                    <filter-menu :fields="filterFields" @filtersUpdated="filtersUpdated" :dense="true"></filter-menu>
                </div>
            </div>
        </v-slide-x-reverse-transition>

        <v-data-table
            :headers="headers"
            :items="trends"
            :loading="loading"
            :sort-desc.sync="sortDesc"
            :sort-by.sync="sortBy"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :server-items-length="count"
            :footer-props="footerProps"
            @update:options="fetchTrends"
            v-model="selectedRows"
            @click:row="onRowClick"
            fixed-header
            height="70vh"
            id="trendsTable"
            item-key="_id"
            class="elevation-0">
            <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                <span :key="index">
                    <span v-bind:style="{ width: header.width }">
                        <v-tooltip max-width="250" top :disabled="header.tooltip ? false : true">
                            <template v-slot:activator="{ on }">
                                <span v-on="on" style="white-space: nowrap; text-transform: uppercase; font-weight: 500">
                                    {{ header.text }}
                                    <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                                </span>
                            </template>
                            <span>
                                <div v-if="header.value == 'keyword_intent'">
                                    <div class="mt-2">
                                        <span><b> Search Intent:</b> </span>
                                    </div>
                                    {{ header.tooltip }}
                                    <div class="mt-2">
                                        <span><b> Intentions:</b> </span>
                                    </div>
                                    <span v-for="(icon, intent) in intent_icons" :key="intent">
                                        <v-chip class="mr-1 mb-1 mt-1" color="primary">
                                            <v-icon size="16px" class="mr-1">{{ icon }}</v-icon> {{ intent }}
                                        </v-chip>
                                        <div>
                                            <p>{{ intentTooltips[intent] }}</p>
                                        </div>
                                    </span>
                                </div>
                                <div v-else>
                                    <v-row no-gutters>
                                        <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                    </v-row>
                                    {{ header.tooltip }}
                                </div>
                            </span>
                        </v-tooltip>
                    </span>
                </span>
            </template>
            <!-- Keyword Column -->
            <template v-slot:item.keyword="{ item }">
                <div class="py-2">
                    <span class="rtd pl-2">
                        {{ item.keyword }}
                        <!-- <v-icon @click="selectedItem = item" x-small class="px-0">mdi-tab-search</v-icon> -->
                    </span>
                    <div style="white-space: nowrap" v-if="item.category_id">
                        <v-btn icon x-small @click="showAllCategories = !showAllCategories"
                            ><v-icon x-small>{{ showAllCategories ? "mdi-chevron-right" : "mdi-chevron-left" }}</v-icon></v-btn
                        >
                        <span v-if="item.category_id && showAllCategories" style="white-space: nowrap">
                            <span v-for="(cat, index) in item.category_id.split(';')" :key="cat">
                                <span v-if="cat">
                                    <v-icon v-if="index" x-small class="px-0">mdi-chevron-right</v-icon>
                                    <v-chip x-small @click="$emit('setCategory', item.category_id, index)"> {{ cat }}</v-chip>
                                </span>
                            </span>
                        </span>
                        <span v-else>
                            <v-chip x-small @click="$emit('setCategory', item.category_id, item.category_id.split(';').filter((c) => c.length).length - 1)">
                                {{ item.category_id.split(";").filter((c) => c.length)[item.category_id.split(";").filter((c) => c.length).length - 1] }}</v-chip
                            >
                        </span>
                    </div>
                </div>
            </template>
            <!-- Latest Monthly_searches Column -->
            <template v-slot:item.movingavg_short="{ item }">
                <!-- {{ item.ads_data.monthly_searches }} -->
                <div>
                    <!-- <v-sparkline
                        class="my-auto"
                        :value="item.sparkline"
                        :gradient="gradient"
                        smooth="4"
                        padding="6"
                        line-width="6"
                        stroke-linecap="round"
                        gradient-direction="top"
                        auto-draw></v-sparkline> -->
                    <v-row no-gutters class="d-flex"
                        ><v-col class="d-flex my-auto" style="position: relative">
                            <v-sparkline
                                class="my-auto"
                                style="max-width: 110px; max-height: 40px"
                                id="sparkline"
                                color="primary"
                                :value="item.sparkline"
                                padding="2"
                                smooth="20"
                                gradient-direction="top"
                                auto-draw
                                :fill="false"></v-sparkline>
                            <v-sparkline
                                class="my-auto"
                                style="max-width: 110px; max-height: 40px; position: absolute"
                                id="sparkline"
                                color="primary"
                                :value="item.sparkline"
                                :gradient="gradient"
                                padding="2"
                                smooth="20"
                                gradient-direction="top"
                                auto-draw
                                :fill="true"></v-sparkline>
                        </v-col>
                        <span class="my-auto">
                            <v-chip class="ml-2 qchip" color="grey darken-1" outlined>
                                <v-icon v-if="item.movingavg_short !== '0.00'" class="mr-1" small :color="item.movingavg_short < 0 ? 'red' : 'green'">{{
                                    item.movingavg_short < 0 ? "mdi-arrow-down" : "mdi-arrow-up"
                                }}</v-icon>
                                <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                                <span class="my-auto">{{ item.movingavg_short ? item.movingavg_short.toFixed(2) : 0 }}%</span>
                            </v-chip>
                        </span>
                    </v-row>
                </div>
            </template>
            <template v-slot:item.keyword_intent="{ item }">
                <div style="display: inline-flex" v-if="item.sorted_search_intent">
                    <!-- Haupt-Intent -->
                    <span class="pa-1 d-flex">
                        <!--    <v-chip outlined class="qchip" color="primary" style="color: grey !important">
                            <v-icon class="mr-1" size="16px" color="primary">{{ intent_icons[item.sorted_search_intent[0][0]] }}</v-icon>
                            {{ Math.ceil(item.sorted_search_intent[0][1]) + "%" }}
                        </v-chip>-->
                        <v-tooltip max-width="250" color="primary" top>
                            <template v-slot:activator="{ on }">
                                <v-progress-linear
                                    v-on="on"
                                    style="border-radius: 16px; font-size: 14px; width: 70px; margin: auto; opacity: 0.8"
                                    class="white--text intent"
                                    rounded
                                    :value="item.sorted_search_intent[0][1]"
                                    color="primary"
                                    height="32">
                                    <v-icon class="mr-1" size="16px" color="white">{{ intent_icons[item.sorted_search_intent[0][0]] }}</v-icon>
                                    {{ Math.ceil(item.sorted_search_intent[0][1]) + "%" }}
                                </v-progress-linear>
                            </template>
                            <span> {{ item.sorted_search_intent[0][0] }}</span>
                        </v-tooltip>
                    </span>
                    <v-slide-x-transition>
                        <span v-if="show_secondary_intents" style="display: inline-flex">
                            <span v-for="(intent, index) in item.sorted_search_intent.slice(1)" :key="index" class="pa-1">
                                <!-- Alle bis auf den ersten Intent -->
                                <!--  <v-chip outlined class="qchip" color="primary" style="color: grey !important">
                                    <v-icon class="mr-1" size="16px" color="primary">{{ intent_icons[intent[0]] }}</v-icon>
                                    {{ Math.ceil(intent[1]) + "%" }}
                                </v-chip> -->
                                <v-tooltip max-width="250" color="primary" top>
                                    <template v-slot:activator="{ on }">
                                        <v-progress-linear
                                            style="border-radius: 15px; font-size: 14px; width: 70px; margin: auto; opacity: 0.8"
                                            v-on="on"
                                            class="white--text intent"
                                            rounded
                                            :value="intent[1]"
                                            color="primary"
                                            height="32">
                                            <v-icon class="mr-1" size="16px" color="white">{{ intent_icons[intent[0]] }}</v-icon>
                                            {{ Math.ceil(intent[1]) + "%" }}
                                        </v-progress-linear>
                                    </template>
                                    <span>{{ intent[0] }} </span>
                                </v-tooltip>
                            </span>
                        </span>
                    </v-slide-x-transition>
                    <v-btn
                        @click="
                            (e) => {
                                e.stopPropagation()
                                show_secondary_intents = !show_secondary_intents
                            }
                        "
                        icon
                        class="ma-auto">
                        <v-icon>{{ show_secondary_intents ? "mdi-chevron-left" : "mdi-chevron-right" }}</v-icon>
                    </v-btn>
                </div>
            </template>
            <!-- Latest Year-Month Column -->
            <template v-slot:item.latestYearMonth="{ item }">
                <v-chip outlined class="qchip" color="primary"> {{ item.latestYearMonth ? $helpers.formatNumber(item.latestYearMonth.search_volume) : "no data" }}</v-chip>
            </template>
            <!-- Last Year-Month Column -->
            <template v-slot:item.lastYearMonth="{ item }">
                <v-chip outlined class="qchip" color="primary"> {{ item.lastYearMonth ? $helpers.formatNumber(item.lastYearMonth.search_volume) : "no data" }}</v-chip>
            </template>
            <!-- Short-Term Trend Column -->
            <!-- <template v-slot:item.short_term_trend="{ item }"> {{ item.short_term_trend.toFixed(2) }}% </template> -->
        </v-data-table>
    </v-card>
</template>

<script>
    import DeleteDialog from "../dialogs/DeleteDialog.vue"
    import SwitchCategoryDialog from "./SwitchCategoryDialog"
    import OpenSerpDialog from "../../components/dialogs/OpenSerpDialog.vue"
    import FilterMenu from "./FilterMenu"
    export default {
        components: {
            DeleteDialog,
            SwitchCategoryDialog,
            OpenSerpDialog,
            FilterMenu
        },
        props: {
            search: {
                type: String
            },
            category_id: {
                type: String
            },
            exportData: {
                type: Number
            }
        },
        data() {
            return {
                show_intents: false,
                show_secondary_intents: false,
                intentTooltips: {
                    informational: "Looking for information on a specific topic, such as “what is SEO?”",
                    navigational: "Navigating to a certain site or page, for instance, “Facebook login page”.",
                    commercial: "Conducting a commercial investigation, that is, researching products or services before making a decision on what to invest in, such as “best SEO tools”.",
                    transactional: "Making a specific purchase, for example, “buy SEO software”."
                },
                intent_icons: { informational: "mdi-information-outline", navigational: "mdi-navigation-outline", commercial: "mdi-shopping-search-outline", transactional: "mdi-shopping-outline" },
                showSwitchDialog: false,
                deleteDialog: false,
                selectedRows: [],
                selectedItem: {},
                showAllCategories: false,
                count: 0,
                loading: false,
                sortDesc: true,
                sortBy: ["movingavg_short"],
                page: 1,
                itemsPerPage: 25,
                pagination: {},
                trends: [],
                gradient: [this.$vuetify.theme.themes.light.sparklineGradient1, this.$vuetify.theme.themes.light.sparklineGradient2, this.$vuetify.theme.themes.light.sparklineGradient3],
                filter: []
            }
        },
        computed: {
            deleteLabel() {
                let str = ""
                let count = this.selectedRows.length
                return str + count + " Keyword(s) permanently?"
            },
            filterFields() {
                let filterFields = [
                    { name: "Keyword", type: "text", key: "keyword" },
                    { name: "Category", type: "text", key: "category_ids" },
                    // { name: "Avg Search Volume", type: "number", key: "avg_search_volume" },
                    // Weitere Felder hier hinzufügen
                    { name: "Trend", type: "number", key: "movingavg_short" },
                    { id: 1, name: "Latest Year-Month", type: "number", key: "ads_data.monthly_searches.0.search_volume" },
                    { id: 2, name: "Last Year-Month", type: "number", key: "ads_data.monthly_searches.11.search_volume" }
                ]
                if (!this.categorized_project) {
                    filterFields.splice(1, 1)
                }
                if (this.show_intents) {
                    Object.keys(this.intent_icons).map((intent) => {
                        let intentheader = {
                            name: "Search Intent - " + intent,
                            key: "search_intent." + intent,
                            type: "number"
                        }
                        filterFields.push(intentheader)
                    })
                }
                return filterFields
            },
            footerProps() {
                return {
                    itemsPerPageOptions: [10, 25, 50, 100],
                    showFirstLastPage: true
                }
            },
            headers() {
                let h = [
                    { text: "Keyword", value: "keyword", tooltip: "" },
                    {
                        text: "Search Trend",
                        value: "movingavg_short",
                        tooltip: "Shows how the the average search volume of the past 2 months compares to the average search volume of the past 12 months.",
                        align: "end",
                        width: "250px"
                    },
                    // { text: "Short-Term Trend", value: "short_term_trend", tooltip: "", align: "end" },
                    { id: 1, text: "Latest Year-Month", value: "latestYearMonth", tooltip: "Shows the search volume of the latest month.", align: "end" },
                    { id: 2, text: "Last Year-Month", value: "lastYearMonth", tooltip: "Shows the search volume of the same month from last year.", align: "end" }
                ]
                // let indexOfSvH = h.findIndex((e) => e.value == "movingavg_short")
                let intentheader = {
                    text: "Search Intent",
                    value: "keyword_intent",
                    sortable: false,
                    align: "end",
                    tooltip: "The search intent refers to the user’s motivation behind a search query."
                    // width: "250px"
                }
                if (this.show_intents) {
                    // h.splice(indexOfSvH + 1, 0, intentheader)
                    h.push(intentheader)
                }

                return h
            }
        },
        methods: {
            filtersUpdated(filters) {
                console.log(filters)
                this.filters = filters
                this.fetchTrends({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
            },
            async fetchTrends(tableOptions) {
                try {
                    let sort = this.getSortObject(tableOptions)
                    this.loading = true
                    const requestData = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id, // Assuming this is the correct way to get the project ID
                        category_id: this.category_id ? this.category_id : null,
                        sortDesc: tableOptions.sortDesc[0] ? -1 : 1,
                        start: (tableOptions.page - 1) * tableOptions.itemsPerPage,
                        limit: tableOptions.itemsPerPage,
                        sort: sort,
                        search: this.search,
                        filters: this.filters
                    }
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/frontend/trends/v2"
                    const response = await this.$helpers.axiosPost(url, requestData)
                    console.log(response.data)
                    this.trends = response.data.trends.map((entry) => {
                        if (entry.ads_data.monthly_searches) {
                            entry.sparkline = entry.ads_data.monthly_searches.reverse().map((searchdata) => searchdata.search_volume) || 0
                            entry.latestYearMonth = entry.ads_data.monthly_searches[entry.ads_data.monthly_searches.length - 1] || 0
                            entry.lastYearMonth = entry.ads_data.monthly_searches[entry.ads_data.monthly_searches.length - 13] || 0
                            if (entry.search_intent) {
                                this.show_intents = true
                                entry.sorted_search_intent = this.sortIntent(JSON.parse(JSON.stringify(entry.search_intent)))
                            }
                        }
                        return entry
                    })
                    this.setHeaderDateString()
                    this.count = response.data.totalCount || 100

                    this.loading = false
                } catch (error) {
                    console.error(error)
                    // Handle error here
                    this.loading = false
                }
            },
            sortIntent(intent) {
                return Object.entries(intent).sort(([, valueA], [, valueB]) => {
                    if (valueA === null && valueB === null) return 0
                    if (valueA === null) return 1
                    if (valueB === null) return -1
                    return valueB - valueA
                })
            },
            async deleteKeywords() {
                console.log(this.selectedRows)
                if (!this.selectedRows.length) return
                const url = process.env.VUE_APP_MONITORING_API_URL + "/project/delete/keywords"
                const post_data = {
                    company_id: localStorage.company_id,
                    project_id: this.$route.params.id,
                    keywords: this.selectedRows
                }
                const response_data = await this.$helpers.axiosPost(url, post_data)
                this.fetchTrends({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
                this.deleteDialog = false
                this.selectedRows = []
                console.log(response_data.data)
                let notifidata = { label: "The selected keywords have been deleted successfully!", icon: "mdi-check-circle-outline", color: "primary" }
                this.$emit("notify", notifidata)
            },
            catUpdated() {
                this.showSwitchDialog = false
                this.fetchTrends({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
                this.selectedRows = []
                let notifidata = { label: "Category successfully changed!", icon: "mdi-check-circle-outline", color: "primary" }
                this.$emit("notify", notifidata)
            },
            onRowClick(row) {
                if (this.isSelecting) return
                console.log(row)
                let entryIndex = this.selectedRows.findIndex((e) => e._id == row._id)
                if (entryIndex === -1) {
                    //select
                    this.selectedRows.push(row)
                } else {
                    //unselect
                    this.selectedRows.splice(entryIndex, 1)
                }
            },

            setHeaderDateString() {
                let latestYearMonthHeader = this.headers.find((h) => h.id == 1)
                let lastYearMonthHeader = this.headers.find((h) => h.id == 2)
                let latestMonthString = this.trends[0].latestYearMonth.month + "/" + this.trends[0].latestYearMonth.year
                let latestYearMonthHeaderFilter = this.filterFields.find((h) => h.id == 1)
                let lastYearMonthHeaderFilter = this.filterFields.find((h) => h.id == 2)

                if (this.trends[0].latestYearMonth.month < 10) {
                    latestMonthString = "0" + latestMonthString
                }
                //Set in headers
                latestYearMonthHeader.text = "Searches " + latestMonthString
                let lastMonthString = this.trends[0].lastYearMonth.month + "/" + this.trends[0].lastYearMonth.year
                if (this.trends[0].lastYearMonth.month < 10) {
                    lastMonthString = "0" + lastMonthString
                }
                lastYearMonthHeader.text = "Searches " + lastMonthString

                // Set in filter fields
                latestYearMonthHeaderFilter.name = "Searches " + latestMonthString
                lastYearMonthHeaderFilter.name = "Searches " + lastMonthString
            },
            getSortObject(tableOptions) {
                let sort = {}
                console.log("tableOptions", tableOptions)
                if (tableOptions.sortBy.length && tableOptions.sortDesc.length) {
                    if (tableOptions.sortBy[0] == "latestYearMonth") {
                        sort["ads_data.monthly_searches.0.search_volume"] = tableOptions.sortDesc[0] ? -1 : 1
                    } else if (tableOptions.sortBy[0] == "lastYearMonth") {
                        sort["ads_data.monthly_searches.11.search_volume"] = tableOptions.sortDesc[0] ? -1 : 1
                    } else {
                        sort[tableOptions.sortBy[0]] = tableOptions.sortDesc[0] ? -1 : 1
                    }
                }
                sort.avg_search_volume = -1
                return sort
            }
            // Add any other necessary methods
        },
        watch: {
            async exportData() {
                console.log("download triggered")
                console.log(this.trends)

                let fields = this.headers.map((header) => header.text)
                let rows = this.trends.map((trend) => {
                    let row = {}
                    this.headers.map((header) => {
                        if (header.value == "keyword") {
                            row[header.text] = header.id ? trend[header.value].search_volume : trend[header.value]
                        } else {
                            row[header.text] = header.id ? parseInt(trend[header.value].search_volume) : parseInt(trend[header.value])
                        }
                    })
                    return row
                })
                console.log(rows, fields)

                let post_data = {
                    rows: rows,
                    fields: fields,
                    fileTitle: "Trends_" + new Date().getTime().toLocaleString("de")
                }
                const url = process.env.VUE_APP_MONITORING_API_URL + "/project/export/csv"
                let response_data = await this.$helpers.axiosPost(url, post_data)
                window.location = process.env.VUE_APP_MONITORING_API_URL + "/" + response_data.data
            },
            search() {
                // Fetch data when the search query changes
                this.page = 1 // Reset the page to 1 when searching
                this.fetchTrends({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
            },
            category_id() {
                console.log(this.sortBy)
                this.fetchTrends({
                    sortDesc: this.sortDesc,
                    sortBy: this.sortBy,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                })
            }
        },
        mounted() {}
    }
</script>

<style>
    .intent div.v-progress-linear__determinate.primary {
        opacity: 0.9 !important;
    }
    #trendsTable th {
        white-space: nowrap !important;
    }
    #trendsTable .theme--light.v-data-table tbody tr.v-data-table__selected {
        background: #8dc4b936 !important;
    }
    #trendsTable table > tbody > tr:hover {
        cursor: pointer;
    }
</style>
