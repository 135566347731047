<template>
    <div>
        <v-dialog v-model="show" max-width="650px" @click:outside="cancel(true)">
            <v-card>
                <v-card-title class="outline_card_label"
                    >AI Headline Generator
                    <v-btn right absolute icon @click="cancel(true)"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>Generate headlines for your content article using AI</v-card-subtitle>
                <v-btn v-if="showResponse" text color="primary" @click="showResponse = false"><v-icon>mdi-chevron-left</v-icon> try again</v-btn>
                <v-card-text v-if="loading">
                    <div style="display: flex">
                        <span class="mx-auto my-10">
                            <img id="loader" src="../../../assets/quaro-q.svg" />
                        </span>
                    </div>
                    <v-col class="d-flex mt-5">
                        <v-btn color="primary" class="mx-auto" @click="cancel(false)">Cancel</v-btn>
                    </v-col>
                </v-card-text>
                <v-card-text v-else-if="!showResponse && !errorMessage">
                    <div class="qgreycard">
                        <div class="quarolbl pb-1">
                            Output Language
                            <v-tooltip max-width="250" color="" top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>The desired languagethe generated outline should be written in!</span>
                            </v-tooltip>
                        </div>
                        <v-select hide-details="" rounded filled :items="languages" v-model="outputLanguage"></v-select>
                    </div>
                    <div class="qgreycard">
                        <div class="quarolbl pb-1">
                            number of variations
                            <v-tooltip max-width="250" color="" top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>The desired language the generated outline should be written in!</span>
                            </v-tooltip>
                        </div>
                        <v-chip-group v-model="optionsCount" mandatory color="cyan darken-2">
                            <v-chip v-for="(variant, index) in variants" :key="variant" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                                {{ variant + 1 }} {{ index > 0 ? "Variants" : "Variant" }}
                            </v-chip>
                        </v-chip-group>
                    </div>
                    <!-- <div class="qgreycard">
                        <div class="quarolbl pb-1">
                            Depth
                            <v-tooltip max-width="250" color="" top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>The desired languagethe generated outline should be written in!</span>
                            </v-tooltip>
                        </div>
                        <v-slider v-model="slider" class="align-center" :max="6" :min="1" hide-details> </v-slider>
                        <b> {{ slider == 1 ? "H1" : "H1 - H" + slider }}</b>
                    </div> -->

                    <!-- {{ prompt }} -->
                </v-card-text>
                <v-card-text v-else>
                    <div v-if="errorMessage">
                        <v-row>
                            <v-icon size="100" class="mx-auto" color="warning">mdi-alert</v-icon>
                        </v-row>
                        <v-row>
                            <v-btn
                                class="ml-auto mr-1"
                                outlined
                                color="grey"
                                @click="
                                    showResponse = false
                                    errorMessage = null
                                "
                                >Cancel</v-btn
                            >
                            <v-btn class="mr-auto ml-1" outlined color="grey" @click="generate">Retry</v-btn>
                        </v-row>
                    </div>

                    <v-tabs centered v-model="tab">
                        <v-tab v-for="(items, index) in responses" :key="index"> {{ "Variant " + (index + 1) }} </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="(items, index) in responses" :key="index">
                            <v-treeview id="outlinetree" class="pb-5" :items="[items]" item-key="id" open-all>
                                <!-- <template v-slot:append="{ item }">
                                        <div class="button-container">
                                            <v-btn icon small @click.stop="editItem(item)">
                                                <v-icon class="outlinebtn" small>mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn icon small @click.stop="addChildItem(item)">
                                                <v-icon class="outlinebtn">mdi-plus</v-icon>
                                            </v-btn>
                                        </div>
                                    </template> -->
                                <template v-slot:label="{ item }">
                                    <div @click="editItem(item)">
                                        <v-divider vertical color="red"></v-divider>
                                        <span class="pl-2"> {{ item.text }}</span>
                                    </div>
                                </template>
                                <template v-slot:prepend="{ item }">
                                    <v-chip class="white--text" @click="editItem(item)" :color="placementColors[item.tag]">{{ item.tag }}</v-chip>
                                </template>
                            </v-treeview>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-card-actions v-if="!showResponse && !errorMessage && !loading">
                    <v-btn color="primary" @click="generate">generate</v-btn>
                    <!-- <v-btn text @click="$emit('close')">Cancel</v-btn> -->
                </v-card-actions>
                <v-card-actions v-if="showResponse && !errorMessage">
                    <v-btn class="mx-auto" color="primary" @click="$emit('insertOutline', responses[tab])">save outline</v-btn>
                    <!-- <v-btn text @click="showResponse = false">Back</v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- <v-dialog v-model="insertDialog" max-width="500px">
            <v-card>
                <v-card-text> This will override your existing Outline! </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="addChildDialog = false">insert Outline</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
    </div>
</template>

<script>
    import axios from "axios"

    export default {
        props: {
            show: Boolean,
            mainKeyword: String,
            projectLanugage: String
        },
        data() {
            return {
                insertDialog: false,
                tab: 0,
                placementColors: {
                    H1: "cyan darken-3",
                    H2: "purple darken-3",
                    H3: "lime darken-3",
                    H4: "light-green darken-3",
                    H5: "blue darken-3",
                    H6: "orange darken-3",
                    description: "grey darken-2",
                    title: "grey darken-2"
                },
                errorMessage: null,
                slider: 4,
                variants: [0, 1, 2],
                languages: [
                    "English",
                    "Spanish",
                    "French",
                    "German",
                    "Italian",
                    "Portuguese",
                    "Dutch",
                    "Russian",
                    "Chinese (Simplified)",
                    "Chinese (Traditional)",
                    "Japanese",
                    "Korean",
                    "Arabic",
                    "Greek",
                    "Hindi",
                    "Swedish",
                    "Norwegian",
                    "Danish",
                    "Finnish",
                    "Polish",
                    "Czech",
                    "Slovak",
                    "Hungarian",
                    "Romanian",
                    "Bulgarian",
                    "Croatian",
                    "Serbian",
                    "Ukrainian",
                    "Turkish",
                    "Hebrew",
                    "Vietnamese"
                ],
                outputLanguage: "German",
                loading: false,
                responses: [],
                optionsCount: 1,
                showResponse: false,
                controller: undefined
            }
        },
        methods: {
            cancel(close) {
                console.log("CLOSINGGGG")
                if (close) this.$emit("close")
                this.loading = false
                console.log(this.controller)
                this.controller.abort()
            },
            async generate() {
                this.loading = true
                this.errorMessage = false
                this.responses = []
                try {
                    let post_data = {
                        message: this.prompt
                    }
                    this.controller = new AbortController()

                    let url = process.env.VUE_APP_APIURL + "/v2/sources/trained-asisstant/completion"
                    let authsplits = process.env.VUE_APP_AUTH.split(":")
                    let response_data = await axios.post(url, post_data, {
                        auth: {
                            username: authsplits[0],
                            password: authsplits[1]
                        },
                        signal: this.controller.signal
                    })
                    // let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log("OUTLINE RESP", response_data)
                    this.responses = JSON.parse(response_data.data.choices[0].message.content)
                    // this.assignUniqueIdsToChildren(this.responses) // Weisen Sie die IDs zu
                    console.log(this.responses)
                    this.showResponse = true
                } catch (error) {
                    if (axios.isCancel(error)) {
                        console.log("cancelled")
                    } else {
                        console.log(error)
                        this.errorMessage = true
                    }
                }

                this.loading = false
            },
            // assignUniqueIdsToChildren(obj) {
            //     if (Array.isArray(obj)) {
            //         // Wenn das Element ein Array ist, rufen wir die Funktion für jedes Element im Array auf
            //         obj.forEach((item) => this.assignUniqueIdsToChildren(item))
            //     } else if (typeof obj === "object" && obj !== null) {
            //         // Wenn das Element ein Objekt ist und eine "children"-Eigenschaft hat, weisen wir eine eindeutige ID zu
            //         if (obj.hasOwnProperty("children")) {
            //             obj.children.forEach((child) => {
            //                 child.id = new Date().getTime().toString() + Math.floor(Math.random() * 1000)
            //                 this.assignUniqueIdsToChildren(child) // Rufen Sie die Funktion rekursiv für die Unterelemente auf
            //             })
            //         }
            //     }
            // },
            formatResponse(str) {
                return new Promise((resolve, reject) => {
                    // let str =
                    //     'Vorschlag 1:\n{\n  "id": 1,\n  "tag": "H1",\n  "text": "Warengruppen erstellen",\n  "children": [\n    {\n      "id": 11,\n      "tag": "H2",\n      "text": "Einführung",\n      "children": []\n    },\n    {\n      "id": 12,\n      "tag": "H2",\n      "text": "Vorteile",\n      "children": []\n    }\n  ]\n}\n\nVorschlag 2:\n{\n  "id": 1,\n  "tag": "H1",\n  "text": "Warengruppen erstellen",\n  "children": [\n    {\n      "id": 11,\n      "tag": "H2",\n      "text": "Schritt 1: Definition der Warengruppen",\n      "children": []\n    },\n    {\n      "id": 12,\n      "tag": "H2",\n      "text": "Schritt 2: Zuordnung von Produkten",\n      "children": []\n    }\n  ]\n}'

                    try {
                        str = str.replace(/\n/g, "").split("Vorschlag")
                        let responses = []
                        str.map((entry, index) => {
                            if (index > 0) {
                                responses.push(JSON.parse(entry.substring(3, entry.length)))
                            }
                        })
                        console.log(responses)
                        resolve(responses)
                    } catch (error) {
                        reject(error)
                    }
                })
            }
        },
        computed: {
            prompt() {
                let str =
                    "Erstelle eine Website Gliederung zu dem Keyword '" +
                    this.mainKeyword +
                    "'" +
                    ". In " +
                    this.outputLanguage +
                    ". Deine Antwort soll folgendes JSON-Format haben: [{ id: 1, vorschlag: 1, tag: 'H1', text: 'Inhalt einer Überschrift', children: [{ id: 11, tag: 'H2', text: 'Überschrift2', children: [] },{ id: 12, tag: 'H2', text: 'Überschrift 3' } ]}, { id: 2, vorschlag: 2, ...]. Der Key Text soll die von dir erstellten Überschriften enthalten. " +
                    " Erstelle " +
                    (this.optionsCount + 1) +
                    " Vorschläge.  Neben H1 und H1 kannst du auch H3, H4, H5 und H6 verwenden! Erstelle möglichst viele Ebenen! Deine Antwort muss ausschließlich Valides JSON sein."
                return str
            }
        }
    }
</script>

<style></style>
