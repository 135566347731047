// these functions can be used without importing

import VueResource from "vue-resource"
import axios from "axios"

export default {
    checkRun(thread_id, run_id) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = process.env.VUE_APP_APIURL + "/v2/sources/content-asisstant/get/run"
                const postData = { thread_id: thread_id, run_id: run_id }
                const res = await axiosPost(url, postData)
                resolve(res)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    },
    trainedAssistant(message) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = process.env.VUE_APP_APIURL + "/v2/sources/trained-asisstant/completion"
                const postData = { message: message }
                const res = await axiosPost(url, postData)
                resolve(res)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    },
    adHocAssistantRun(message, assistant) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = process.env.VUE_APP_APIURL + "/v2/sources/content-asisstant/adhocrun"
                const postData = { message: message }
                if (assistant) {
                    postData.assistant = assistant
                }
                const res = await axiosPost(url, postData)
                resolve(res)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    },
    adHocHeadlinesAssistantRun(message, assistant, headlines, socket) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = process.env.VUE_APP_APIURL + "/v2/sources/content-asisstant/headlines"
                const postData = { message: message, headlines: headlines, socket: socket }
                if (assistant) {
                    postData.assistant = assistant
                }
                const res = await axiosPost(url, postData)
                resolve(res)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }
}
function axiosPost(url, data) {
    return new Promise(async (resolve, reject) => {
        try {
            let authsplits = process.env.VUE_APP_AUTH.split(":")
            const response = await axios({
                method: "post",
                url: url,
                data: data,
                auth: {
                    username: authsplits[0],
                    password: authsplits[1]
                }
            })
            resolve(response)
        } catch (error) {
            reject(error)
        }
    })
}
