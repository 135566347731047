<template>
    <div v-if="searched">
        <!-- <v-btn class="mb-5" @click="fetchSERP('garten')">test</v-btn> -->
        <v-dialog v-model="addURLdialog" max-width="500px" transition="slide-x-transition">
            <v-card>
                <div class="backsheet"></div>
                <v-card-text class="pt-5 pb-0">
                    <div class="lbl pb-1">ADD WEBSITE URL</div>
                    <v-text-field type="text" clearable class="my-2" rounded filled v-model="dialog_preview_url" hint="e.g. https://www.quaro.io/keyword-recherche" persistent-hint></v-text-field>
                    <div>
                        <span style="color: red" v-if="url_error_msg && dialog_preview_url">{{ url_error_msg }}</span>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mx-auto" color="primary" @click="previewURlEntered" rounded>ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card class="rounded_card mt-0 quaroshadow">
            <div class="backsheet"></div>
            <v-card-title class="pb-2"
                >SERP Preview

                <!-- --------------- -->
                <!-- compare button  -->
                <!-- --------------- -->
                <div cols="12" sm="8" md="4" xl="2" class="inline-flex ml-auto">
                    <span class="ml-auto inline-flex">
                        <v-progress-linear v-if="compared" id="compare_progress" readonly rounded :value="compare_percent" color="primary" height="24">
                            <span id="compare_value"> {{ compare_percent }}% </span>
                            <!-- <v-icon class="" small color="white">mdi-percent-circle-outline</v-icon> -->
                        </v-progress-linear>
                        <!-- <Difficulty class="pr-2" :difficulty="{ difficulty: compare_percent }" icon="mdi-percent-circle" v-if="compared" small></Difficulty> -->

                        <v-btn class="my-auto" v-if="fetched_serps.length > 1" @click="switchCompare()" color="primary" outlined small>
                            <v-icon class="mr-1" v-if="!compare_awaiting" small>mdi-scale-unbalanced</v-icon>
                            <div v-else-if="items.length < 2" class="compare-loader">
                                <v-progress-circular class="mx-auto mr-2" color="primary" size="16" width="2" indeterminate></v-progress-circular>
                            </div>
                            <span v-if="items.length < 2 && !compare_awaiting">compare</span>

                            <div v-else-if="compared" class="flex">
                                <span class="ma-auto"> clear </span>
                                <v-icon small class="ml-1">mdi-close</v-icon>
                            </div>
                            <span v-else>select Serps...</span>
                        </v-btn>
                    </span>
                </div>
                <v-btn
                    @click="
                        addURLdialog = true
                        url_error_msg = null
                        dialog_preview_url = preview_url
                    "
                    v-if="!compared && !preview_url"
                    outlined
                    class="ml-1"
                    small
                    color="primary">
                    <v-icon small class="mr-1">mdi-search-web</v-icon> add url</v-btn
                >
                <div
                    v-if="preview_url && !compared"
                    class="inline-flex yourpos"
                    @click="
                        addURLdialog = true
                        url_error_msg = null
                        dialog_preview_url = preview_url
                    ">
                    <div class="flex">
                        <span class="mr-2 ma-auto" style="margin-top: 1px">URL POSITION:</span>
                    </div>
                    <div class="flex">
                        <v-chip style="cursor: pointer" class="ma-auto" small color="primary">{{ preview_url_pos }}</v-chip>
                    </div>
                </div>
            </v-card-title>

            <v-card-subtitle id="serp_preview_subtitle" class="pb-1 pt-1">
                <!-- ------------------- -->
                <!-- serp chips actions  -->
                <!-- ------------------- -->
                <v-row no-gutters v-if="items.length == 1">
                    <v-col cols="12">
                        <v-chip-group color="primary" v-model="visible_serp" mandatory :multiple="compare_awaiting ? true : false" max="2" next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                            <v-chip
                                v-for="(serp, index) in fetched_serps"
                                :key="index"
                                class="serp_chip"
                                small
                                filter
                                close-icon="mdi-close-circle-outline"
                                @click:close="removeSERP(index)"
                                :close="fetched_serps.length > 1 ? true : false"
                                :filter-icon="fetched_serps.length > 1 ? 'mdi-check-circle' : ''">
                                {{ serp.keyword }}
                            </v-chip>
                        </v-chip-group>
                    </v-col>
                </v-row>
            </v-card-subtitle>

            <!-- ---------------- -->
            <!--    serp table    -->
            <!-- ---------------- -->
            <v-layout fluid v-resize="onResize" child-flex>
                <v-row no-gutters>
                    <v-col v-for="(serp_items, index) in items" :key="index" :cols="12 / items.length" :class="items.length > 1 && index < items.length - 1 ? 'pr-3' : ''">
                        <div class="compare-keyword-title">
                            <v-chip class="mb-2 ml-2" small color="primary" v-if="items.length > 1">{{ serp_items.keyword }}</v-chip>
                        </div>
                        <v-data-table
                            id="serp_table"
                            fixed-header
                            :height="tableHeight"
                            :items-per-page="serp_items.rowsPerPage"
                            :loading="loading"
                            :headers="headers"
                            :items="serp_items.items"
                            class="elevation-0 second-fixed"
                            item-key="id"
                            hide-default-footer>
                            <!-- header slot -->
                            <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                                <span :key="index">
                                    <span v-bind:style="{ width: header.width }">
                                        <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on">
                                                    {{ header.text }}
                                                    <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                                </span>
                                            </template>

                                            <span>
                                                <v-row no-gutters>
                                                    <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                                </v-row>

                                                {{ header.tooltip }}
                                            </span>
                                        </v-tooltip>
                                    </span>
                                </span>
                            </template>

                            <!-- Table Body -->
                            <template v-slot:body="{ items }">
                                <tbody v-if="items.length">
                                    <tr
                                        v-for="(item, itemIndex) in items"
                                        :key="itemIndex"
                                        :class="item.selected ? 'highlighted' : ''"
                                        :style="item.compare_color && compared ? 'background-color: rgba(' + item.compare_color + ',0.3);' : ''">
                                        <td v-for="(header, headerIndex) in headers" :key="headerIndex">
                                            <!-- ------- -->
                                            <!-- position -->
                                            <!-- ------- -->
                                            <div v-if="header.value === 'position'" class="td_position">
                                                {{ item[header.value] }}
                                            </div>
                                            <!-- ------- -->
                                            <!---- URL----->
                                            <!-- ------- -->
                                            <div v-if="header.value === 'url'" class="td_url">
                                                <div class="url my-auto">
                                                    <span class="domain">{{ item.domain }}</span>
                                                    <span v-if="item.path">{{ item.path }}</span>
                                                    <span v-if="item.searchParams">{{ item.searchParams }}</span>
                                                </div>
                                                <v-btn :href="item.url" target="_blank" class="ml-2" small icon><v-icon small>mdi-open-in-new</v-icon></v-btn>
                                            </div>
                                            <!-- ------- -->
                                            <!---- PR----->
                                            <!-- ------- -->
                                            <div v-if="header.value === 'page_rank'">
                                                <v-chip v-if="item[header.value] >= 0" small class="white--text" :color="getRankColor(item[header.value])">{{ item[header.value] }}</v-chip>
                                                <span v-else>-</span>
                                            </div>
                                            <!-- ------ -->
                                            <!---- DR----->
                                            <!-- ------ -->
                                            <div v-if="header.value === 'domain_rank'">
                                                <v-chip v-if="item[header.value] >= 0" small class="white--text" :color="getRankColor(item[header.value])">{{ item[header.value] }}</v-chip>
                                                <span v-else>-</span>
                                            </div>
                                            <!-- ------- -->
                                            <!---- EV----->
                                            <!-- ------- -->
                                            <div v-if="header.value === 'estimated_visits'">
                                                <v-chip v-if="item[header.value] >= 0" small>{{ $helpers.formatNumber(parseInt(item[header.value])) }}</v-chip>
                                                <span v-else>-</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else-if="noCreditsLeft">
                                    <tr :height="tableHeight - 52">
                                        <td colspan="5">
                                            <div class="no_credits_msg_container">
                                                <img src="../assets/no_credits_illustration.svg" class="no_credits_image" /><br />
                                                <span class="no_credits_text">No Credits Left</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>

                            <!-- ------------- -->
                            <!-- table footer  -->
                            <!-- ------------- -->
                            <template v-slot:footer>
                                <div class="tablefooter">
                                    <v-btn
                                        @click="loadNextPage(serp_items)"
                                        :disabled="compare_awaiting"
                                        v-if="items.length > 0"
                                        color="primary"
                                        loading-text="Loading SERP..."
                                        text
                                        class="mx-auto my-2">
                                        <v-icon v-if="!nextPageLoading">mdi-plus</v-icon>
                                        <v-progress-circular v-else class="mx-auto" color="primary" size="16" width="2" indeterminate></v-progress-circular>
                                        load next page
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-layout>
        </v-card>
    </div>
</template>

<script>
    import { click_rates } from "./helper/global.js"
    import { colorCodes } from "./helper/global.js"
    import datasources from "./helper/datasources.js"
    import Difficulty from "./keyword-table/Difficulty"

    var startIndex = 0
    export default {
        props: {
            keyword: String,
            reload: Number,
            selectedLocation: Object,
            search_volume: Object,
            searched: Boolean,
            resize: Number
        },
        components: {
            Difficulty
        },
        data() {
            return {
                nextPageLoading: false,
                url_error_msg: null,
                dialog_preview_url: null,
                addURLdialog: false,
                noCreditsLeft: false,
                compare_percent: 0,
                fullscreen: false,
                compared: false,
                compare_awaiting: false,
                visible_serp: null,
                tableHeight: 0,
                plattform: "Mobile",
                itemsPerPage: 10,
                loading: false,
                headers: [
                    {
                        text: "Pos.",
                        value: "position",
                        width: "20",
                        sortable: false
                    },
                    {
                        text: "URL",
                        value: "url",
                        sortable: false
                    },
                    {
                        text: "Page Rank",
                        value: "page_rank",
                        sortable: false,
                        tooltip:
                            "Page Rank is an algorithm designed to measure the importance of a webpage based on the quality and quantity of backlinks. High Values are perceived as more authoritative by Google and rank better"
                    },
                    {
                        text: "Domain Rank",
                        value: "domain_rank",
                        sortable: false,
                        tooltip:
                            "Domain Rank is an algorithm designed to measure the importance of a domain based on the quality and quantity of backlinks. High Values are perceived as more authoritative by Google and rank better"
                    },
                    {
                        text: "Estimated Visits",
                        value: "estimated_visits",
                        sortable: false,
                        tooltip: "Eestimated visits are calcuated using the search volume and the average click-through rate of the respective position"
                    }
                ],
                items: [[]],
                card: { title: "SERP Preview", subtitle: "just now" },
                serp_items_location: {}
            }
        },
        watch: {
            search_volume(obj) {
                if (!obj) return
                this.addEstimatedVisits(obj)
            },
            compare_awaiting(val) {
                if (!val) return
                setTimeout(() => {
                    if (this.compare_awaiting == true) {
                        this.compare_awaiting == false
                    }
                }, 10000)
            },
            reload() {
                console.log("serp lang", this.serp_items_location)
                console.log("selected lang", this.selectedLocation)
                console.log(this.serp_items_location.serp_lang_code !== this.selectedLocation.serp_lang_code)
                console.log(this.serp_items_location.serp_lang_code !== this.selectedLocation)

                if (this.serp_items_location.serp_lang_code !== this.selectedLocation.serp_lang_code) {
                    this.items = [[]]
                    this.fetched_serps = []
                }

                if (this.selectedLocation.serp_lang_code !== null) {
                    this.fetchSERP()
                    this.serp_items_location = this.selectedLocation
                }
            },
            visible_serp(val) {
                console.log("visible_serp", val)
                // if (val) {
                if (this.compare_awaiting) {
                    if (val.length == 1) val.unshift(this.latest_visible_serp)
                    if (val.length > 1) {
                        this.startComparison(val)
                    }
                    return
                }
                this.latest_visible_serp = val
                this.processSerp(this.fetched_serps[val])
                // }
            },
            resize() {
                this.onResize()
            }
        },
        computed: {
            preview_url: {
                get: function () {
                    return this.$store.state.preview_url
                },
                set: function (newValue) {
                    try {
                        if (newValue) {
                            let checkurl = new URL(newValue.trim())
                        }

                        this.$store.dispatch("CHANGE_PREVIEW_URL", newValue)
                        this.addURLdialog = false
                    } catch (error) {
                        console.log(error)
                        this.url_error_msg = " Please enter a valid website URL!"
                    }
                }
            },
            fetched_serps: {
                get: function () {
                    return this.$store.state.fetched_serps
                },
                set: function (newValue) {
                    this.$store.dispatch("CHANGE_FETCHED_SERPS", newValue)
                }
            },
            preview_url_pos() {
                if (this.fetched_serps[this.visible_serp]) {
                    let organic_results = this.fetched_serps[this.visible_serp].items.filter((item) => item.type == "organic")
                    let preview_url_pos = organic_results.find((entry) => entry.url.includes(this.preview_url))
                    return preview_url_pos ? preview_url_pos.rank_group : " - "
                }
                return
            }
        },
        methods: {
            async fetchSERP(testkeyword) {
                this.compared = false
                this.compare_awaiting = false
                this.items.length = 1
                let keyword = testkeyword ? testkeyword : this.keyword
                let existing_serp_index = this.fetched_serps.findIndex((serp) => serp.keyword == keyword)
                if (existing_serp_index !== -1) {
                    this.processSerp(this.fetched_serps[existing_serp_index])
                    this.visible_serp = existing_serp_index
                    return
                }
                this.loading = true
                let options = {
                    keyword: keyword,
                    language_code: this.selectedLocation.serp_lang_code,
                    location_code: this.selectedLocation.value,
                    device: "mobile"
                }
                console.log(options)
                let serp_response = await datasources.fetchDFS(options, localStorage.company_id, localStorage.id, "google_serps_live")
                if (serp_response.error) {
                    this.noCreditsLeft = true
                    this.loading = false
                    return
                }
                if (this.search_volume && this.search_volume.value) {
                    serp_response.result.search_volume = this.search_volume.value
                }
                serp_response.result.rowsPerPage = 10
                this.visible_serp = 0
                this.saveResponse(serp_response.result)
                this.processSerp(serp_response.result)
            },
            processSerp(result, itemindex = 0) {
                if (!result) return
                if (!this.items[itemindex]) {
                    this.items[itemindex] = { items: [] }
                }
                this.items[itemindex].keyword = result.keyword
                this.items[itemindex].items = result.items
                    .filter((item) => item.type == "organic")
                    .map((item) => {
                        let obj = {}
                        obj.url = item.url
                        obj.title = item.title
                        obj.position = item.rank_group
                        obj.domain = item.domain.replace(/(https?:\/\/)?(www[0-9]?\.)?/i, "", "")
                        let url = new URL(item.url)
                        obj.path = url.pathname
                        obj.params = url.searchParams
                        if (this.search_volume && this.search_volume.keyword == result.keyword) {
                            obj.estimated_visits = this.calcEV(item.rank_group, this.search_volume.value)
                            result.search_volume = this.search_volume.value
                        } else {
                            obj.estimated_visits = result.search_volume ? this.calcEV(item.rank_group, result.search_volume) : 0
                        }

                        return obj
                    })

                this.items[itemindex].rowsPerPage = result.rowsPerPage

                if (this.compare_awaiting) {
                    this.compare()
                }
                this.fetchPageRanks(itemindex)
                this.loading = false
                this.$emit("serpLoading")
            },
            async fetchPageRanks(itemindex = 0, showload = false) {
                if (showload) {
                    this.nextPageLoading = true
                }
                let rank_targets = this.items[itemindex].items.slice(0, this.items[itemindex].rowsPerPage).map((item) => item.url)
                let rank_targets_domains = this.items[itemindex].items.slice(0, this.items[itemindex].rowsPerPage).map((item) => item.domain)
                rank_targets = [...rank_targets, ...rank_targets_domains]

                let rank_response = await datasources.fetchDFS({ targets: rank_targets }, localStorage.company_id, localStorage.id, "backlink_bulk_rank")
                rank_response = rank_response.result.items
                this.items[itemindex].items.map((item) => {
                    let page_rank = rank_response.find((entry) => item.url == entry.target)
                    let domain_rank = rank_response.find((entry) => item.domain == entry.target)
                    if (page_rank) item.page_rank = page_rank.rank
                    if (domain_rank) item.domain_rank = domain_rank.rank
                })
                this.nextPageLoading = false
                this.items.push({})
                this.items.pop()
                this.onResize()
            },
            loadNextPage(item) {
                item.rowsPerPage += 10

                //TODO: increment rowsPerPage value in saved result to prevent override
                this.fetchPageRanks(0, true)
            },
            getRankColor(rank) {
                // #2DFF3D #6AEB50 #9EDD4E #D1EB57 #E6D05E #EDA653 #EDA653 #EB2727 #B80101 #8D0202
                let colors = ["#00A30E", "#00A30E", "#00B80F", "#9EDD4E", "#DDE236", "#E6D05E", "#F4BC7B", "#EDA653", "#EB2727", "#B80101", "#8D0202"]
                let helper = (rank / 100).toFixed(0)
                return colors[helper]
            },
            calcEV(position, search_volume) {
                if (position < 21) {
                    let ctr = click_rates.find((entry) => entry.position == position)
                    return ((ctr[this.plattform] / 100) * search_volume).toFixed(0)
                }
                return 0
            },
            addEstimatedVisits(obj) {
                let items = this.items.find((serp) => serp.keyword == obj.keyword)
                if (items && obj.value) {
                    items.items.map((serp_entry) => {
                        serp_entry.estimated_visits = this.calcEV(serp_entry.position, obj.value)
                    })
                }
            },
            onResize() {
                setTimeout(() => {
                    const serp_preview_subtitle = document.getElementById("serp_preview_subtitle")
                    const compare_title = document.getElementsByClassName("compare-keyword-title")
                    if (serp_preview_subtitle && this.items.length < 2) {
                        const subtitle_position = serp_preview_subtitle.getBoundingClientRect()
                        const subtitle_height = serp_preview_subtitle.offsetHeight
                        const footerHeight = 52
                        const windowHeight = window.innerHeight
                        this.tableHeight = windowHeight - subtitle_position.y - footerHeight - 12 - subtitle_height
                    } else if (compare_title && compare_title[0]) {
                        const subtitle_position = compare_title[0].getBoundingClientRect()
                        const subtitle_height = compare_title[0].offsetHeight
                        const footerHeight = 52
                        const windowHeight = window.innerHeight
                        this.tableHeight = windowHeight - subtitle_position.y - footerHeight - 12 - subtitle_height
                    }
                }, 100)
            },
            saveResponse(serp_result) {
                // for perfomance dont save moere than 10 serps
                if (this.fetched_serps.length >= 10) {
                    this.fetched_serps.pop()
                }
                this.fetched_serps.unshift(serp_result)
            },
            startComparison(serp_indexes) {
                for (let index = 0; index < serp_indexes.length; index++) {
                    this.processSerp(this.fetched_serps[serp_indexes[index]], index)
                }
            },
            compare(urls) {
                if (this.items.length > 1) {
                    // this.$emit("toggle_linechart")
                    // this.onResize()
                    this.compare_percent = 0
                    for (let index = 0; index < 10; index++) {
                        let itemIndex = this.items[1].items.findIndex((result) => result.url == this.items[0].items[index].url)
                        if (itemIndex != -1 && itemIndex <= 9) {
                            this.items[0].items[index].compare_color = colorCodes[index]
                            this.items[1].items[itemIndex].compare_color = colorCodes[index]
                            this.compare_percent += 10
                        }
                    }
                    this.compared = true
                }
            },
            switchCompare() {
                this.compare_awaiting = !this.compare_awaiting
                if (this.compare_awaiting == false) {
                    this.items.length = 1
                    this.compared = false
                    this.visible_serp = this.visible_serp[0]
                }
            },
            removeSERP(index) {
                this.fetched_serps.splice(index, 1)
            },
            toggleFullscreen() {
                this.fullscreen = !this.fullscreen
                this.$emit("toggle_linechart")
                this.onResize()
            },
            previewURlEntered() {
                this.preview_url = this.dialog_preview_url
            }
        },
        mounted() {
            if (this.keyword && this.selectedLocation.serp_lang_code !== null) {
                this.fetchSERP()
                this.serp_items_location = this.selectedLocation
            }
        }
    }
</script>

<style lang="scss">
    .serp_chip .v-icon {
        font-size: 18px !important;
    }
    .tablefooter {
        display: flex;
    }
    .domain {
        font-weight: bold;
        color: var(--v-primary-base) !important;
    }
    .td_url {
        display: inline-flex;
    }
    .url {
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        max-width: 20vw;
    }

    #serp_table > div.v-data-table__wrapper > table > thead > tr > th:nth-child(1),
    #serp_table > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(1) {
        background: var(--v-keywordcol-base) !important;
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 0 !important;
    }
    #serp_table > .second-fixed > div.v-data-table__wrapper > table > thead > tr > th:nth-child(2),
    #serp_table > .second-fixed > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(2) {
        background: var(--v-keywordcol-base) !important;
        position: sticky !important;
        position: -webkit-sticky !important;
        left: calc(54px);
    }
    #serp_table > div.v-data-table__wrapper > table > thead > tr > th:nth-child(1) {
        z-index: 3 !important;
    }
    #serp_table > .second-fixed > div.v-data-table__wrapper > table > thead > tr > th:nth-child(2) {
        z-index: 3 !important;
    }
    #serp_table > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(1) {
        z-index: 2 !important;
    }
    #serp_table > .second-fixed > div.v-data-table__wrapper > table > tbody > tr > td:nth-child(2) {
        z-index: 2 !important;
    }
    .td_position {
        font-weight: bold;
    }
    .compare-loader {
        display: flex;
        width: 24px;
    }
    #serp_preview_subtitle > div > div > div > div.v-slide-group__prev {
        min-width: 30px;
        margin-left: -20px;
    }
    #serp_preview_subtitle {
        color: rgba(0, 0, 0, 1) !important;
    }
    .fullscreen-btn {
        position: absolute;
        right: 3px;
        top: 3px;
    }
    #serp_table table > thead > tr > th {
        /* padding-left: 8px !important;
        padding-right: 8px !important; */
        background-color: var(--v-keywordcol-base);
    }
    // .compare_btn {
    //     position: absolute;
    //     top: 7px;
    //     right: 5px;
    // }
    .no_credits_msg_container {
        display: block;
        text-align: center;
    }
    .no_credits_image {
        max-width: 30%;
        height: auto;
        margin: auto;
    }
    .no_credits_text {
        font-weight: bold;
        font-size: 1em;
    }
    .yourpos {
        background-color: #f3f3f3;
        color: rgba(0, 0, 0, 0.6);
        padding: 0px 15px;
        border-radius: 5px;
        font-size: 14px;
        margin-left: 5px;
        &:hover {
            cursor: pointer !important;
            background-color: #e6e6e6;
        }
    }
    #compare_progress {
        border-radius: 12px;
        width: 100px;
        font-size: 12px;
        color: white;
        margin-right: 5px;
        margin-top: 2px;
    }
    #compare_value {
        font-weight: bold;
        font-size: 12px;
    }
    @media only screen and (max-height: 1000px) {
        .no_credits_image {
            height: 100px;
        }
    }
</style>
