<template>
    <div>
        <div v-if="loading" class="loaderwrapper">
            <v-row class="loaderimg mb-10">
                <img id="loader" src="../../../assets/quaro-q.svg" />
            </v-row>
            <v-row class="loaderheading">
                <span style="font-weight: 300; font-size: 16px" class="mx-auto">{{ statusNotification.message }}...</span>
            </v-row>
            <v-row class="ma-auto">
                <v-col>
                    <div id="loaderdiv">
                        <v-progress-linear
                            rounded
                            :indeterminate="statusNotification.message ? false : true"
                            height="6px"
                            class="ma-auto"
                            color="primary"
                            :value="statusNotification.progress"></v-progress-linear>
                    </div>
                </v-col>
            </v-row>
            <v-col class="d-flex mt-10" v-if="statusNotification.type == 'error'">
                <v-btn color="primary" class="mx-auto" @click="$emit('retryProcess', 3)"> Retry <v-icon>mdi-refresh</v-icon> </v-btn>
            </v-col>

            <p class="loadersubtext mt-10">
                We analyse the content of your competitors' websites. What do your competitors write about? With the help of intelligent text processing and a WDF*IDF analysis, we show you the phrases
                you can use if you want to achieve a good position in the Google search results.
            </p>
            <p class="loadersubtext highlighted">Note: This process usually takes a few seconds. You can leave this page and return later!</p>
        </div>
        <div v-else>
            <div v-if="setup">
                <h2 style="font-weight: 300">Select Content Terms</h2>
                <p>
                    We analyzed the content of your competitors' websites. What do your competitors write about? With the help of intelligent text processing and a WDF*IDF analysis, we show you the
                    phrases you can use if you want to achieve a good position in the Google search results. Now, you can select the terms from the WDF*IDF analysis table which you would like to
                    include in your content briefing.
                </p>
            </div>

            <div class="comp-table-area">
                <v-row class="pa-4">
                    <span>
                        <v-chip class="white--text" color="darkgreen" small> {{ selected.length }} selected</v-chip>
                    </span>

                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-text-field
                        clearable
                        clear-icon="mdi-close"
                        dense
                        rounded
                        filled
                        v-model="search"
                        max-width="300px"
                        prepend-inner-icon="mdi-magnify"
                        label="search keywords..."
                        single-line
                        hide-details></v-text-field>
                </v-row>
                <v-data-table
                    disabled
                    id="cs_comp_table"
                    show-select
                    :search="search"
                    v-model="selected"
                    fixed-header
                    height="600px"
                    :loading="!topKeywords.length"
                    :headers="headers"
                    :items="topKeywords"
                    class="elevation-0 comp-table"
                    @toggle-select-all="selectPage"
                    :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
                    :items-per-page="25"
                    item-key="id"
                    :custom-sort="customSort">
                    <!-- header slot -->
                    <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                        <span :key="index">
                            <span v-bind:style="{ width: header.width }">
                                <!-- Adding tooltips to header -->
                                <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="quarotblheader">
                                            {{ header.text }}
                                            <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                        </span>
                                    </template>
                                    <span>
                                        <v-row no-gutters>
                                            <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                        </v-row>
                                        {{ header.tooltip }}
                                    </span>
                                </v-tooltip>
                            </span>
                        </span>
                    </template>

                    <!-- Table Body -->
                    <template v-slot:body="{ items }">
                        <tbody v-if="items.length">
                            <tr v-for="(item, itemIndex) in items" :key="itemIndex" :class="item.selected ? 'highlighted' : ''">
                                <td class="selecttd">
                                    <v-icon v-if="!item.selected" @click="selectItem(itemIndex, item)" style="margin: 0px; padding: 0px">mdi-checkbox-blank-outline</v-icon>
                                    <v-icon v-else @click="selectItem(itemIndex, item)" style="margin: 0px; padding: 0px">mdi-checkbox-marked</v-icon>
                                </td>
                                <td v-for="(header, headerIndex) in headers" :key="headerIndex" class="table_td">
                                    <!-- ------- -->
                                    <!-- keyword -->
                                    <!-- ------- -->
                                    <div v-if="header.value === 'keyword'" class="td_keyword">
                                        {{ item.variants ? item.variants.reduce((a, b) => (a.length < b.length ? a : b)) : item.keyword }}
                                    </div>
                                    <!-- -------------- -->
                                    <!---- relevance ----->
                                    <!-- -------------- -->
                                    <div v-if="header.value === 'relevance'" v-bind="(relevance = getRelevance(item))">
                                        <v-progress-linear style="border-radius: 12px; font-size: 12px" class="white--text" rounded :value="relevance.value" color="primary" height="24">
                                            {{ relevance.value }}%
                                        </v-progress-linear>
                                    </div>
                                    <!-- -------------- -->
                                    <!------- avgsv ------->
                                    <!-- -------------- -->
                                    <!-- <div v-if="header.value === 'avgsv'">
                                        <v-chip small color="primary"> {{ $helpers.formatNumber(item[header.value]) }}</v-chip>
                                    </div> -->
                                    <!-- -------------- -->
                                    <!---- url_coverage ----->
                                    <!-- -------------- -->
                                    <div v-if="header.value === 'url_coverage'">
                                        <v-progress-linear
                                            class="white--text"
                                            style="border-radius: 12px; font-size: 12px"
                                            rounded
                                            :value="getPercent(item[header.value])"
                                            color="cyan darken-1"
                                            height="24">
                                            {{ getPercent(item[header.value]) }}%
                                        </v-progress-linear>
                                    </div>
                                    <!-- -------------- -->
                                    <!---- avgKeywordFrequency ----->
                                    <!-- -------------- -->
                                    <div v-if="header.value === 'avgKeywordFrequency'">{{ item[header.value] ? item[header.value].toFixed(0) : 0 }}</div>
                                    <!-- ------- -->
                                    <!---- avg_wdf----->
                                    <!-- ------- -->
                                    <div v-if="header.value === 'avgWDF'">
                                        {{ item[header.value] ? item[header.value].toFixed(3) : 0 }}
                                    </div>
                                    <!-- ------- -->
                                    <!---- avg_wdfidf----->
                                    <!-- ------- -->
                                    <div v-if="header.value === 'avgWDFIDF'">
                                        {{ getWDFIDF(item) ? getWDFIDF(item) : 0 }}
                                    </div>
                                    <!-- ------- -->
                                    <!---- word_count----->
                                    <!-- ------- -->
                                    <div v-if="header.value === 'word_count'">
                                        {{ item.keyword.split(" ").length }}
                                    </div>
                                    <!-- ------ -->
                                    <!---- idf----->
                                    <!-- ------ -->
                                    <div v-if="header.value === 'idf'">
                                        {{ item[header.value] ? item[header.value].idf.toFixed(3) : 0 }}
                                    </div>
                                    <!-- ------ -->
                                    <!---- termplacement----->
                                    <!-- ------ -->
                                    <div v-if="header.value === 'termplacement'">
                                        <v-chip-group>
                                            <v-chip small class="white--text mr-1 my-1" v-for="(placement, index) in item[header.value]" :key="index" :color="colors[placement.tag]">{{
                                                placement.tag
                                            }}</v-chip>
                                        </v-chip-group>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            project: Object,
            loading: Boolean,
            topKeywords: Array,
            statusNotification: Object,
            setup: Boolean
        },
        data() {
            return {
                progress: 80,
                colors: {
                    h1: "cyan darken-3",
                    h2: "purple darken-3",
                    h3: "lime darken-3",
                    h4: "light-green darken-3",
                    h5: "blue darken-3",
                    h6: "orange darken-3",
                    description: "grey darken-2",
                    title: "grey darken-2"
                },
                search: "",
                headers: [
                    {
                        text: "Term",
                        value: "keyword",
                        sortable: true
                    },
                    // {
                    //     text: "Ø Search Volume",
                    //     value: "avgsv",
                    //     sortable: true
                    // },
                    {
                        text: "Relevance",
                        value: "relevance",
                        sortable: true,
                        width: "150px",
                        tooltip: "A metric that indicates how relevant a term or keyword for the given topics. The relevance is based on the termplacements, the url coverage and the wdf*idf values."
                    },
                    {
                        text: "URL Coverage",
                        value: "url_coverage",
                        sortable: true,
                        width: "150px",
                        tooltip: "This column shows the number of documents where the keyword appears. It can help you understand how many of your competitors are using the same keyword."
                    },
                    {
                        text: "Term Placement",
                        value: "termplacement",
                        sortable: false,
                        tooltip: "This column shows the location of the keyword in the document. It can help you understand where your competitors are placing the keyword on their website."
                    },
                    {
                        text: "Ø WDF*IDF",
                        value: "avgWDFIDF",
                        sortable: true,
                        tooltip:
                            "The WDF multiplied by Inverse Document Frequency (WDF*IDF) is a value that indicates how important the keyword is to the document. WDFIDF min/max/Ø show the minimum, maximum, and average WDF*IDF values for the keyword across all documents."
                    },
                    {
                        text: "Ø WDF",
                        value: "avgWDF",
                        sortable: true,
                        tooltip:
                            "The Within Document Frequency (WDF) is a weighted value that represents the keyword density within a document. WDF Ø shows average WDF value for the keyword across all documents."
                    },
                    {
                        text: "IDF",
                        value: "idf",
                        sortable: true,
                        tooltip:
                            "The Inverse Document Frequency (IDF) is a value that indicates how important the keyword is across all documents. A high IDF value means that the keyword is rare and appears in fewer documents."
                    },
                    {
                        text: "Ø Keyword Frequency",
                        value: "avgKeywordFrequency",
                        sortable: true,
                        tooltip: "This column shows the average number of times the keyword appears in each document. It can help you understand how frequently your competitors are using the keyword."
                    },
                    {
                        text: "Word Count",
                        value: "word_count",
                        sortable: true,
                        tooltip: "Shows you how many words the term or phrase consists of."
                    }
                ],
                relevanceData: {
                    maxWDF: 0,
                    minWDF: 0,
                    minURLCoverage: 0,
                    maxURLCoverage: 0,
                    minPlacementValue: 0,
                    maxPlacementValue: 0
                }
            }
        },
        watch: {
            statusNotification(newNotification) {
                console.log("statusNotification watched")
                console.log(newNotification)
            },
            async topKeywords(keywords) {
                console.log("keywords watched")
                this.calcRelevanceData(keywords)
            }
        },
        computed: {
            selected: {
                get() {
                    let selectedtopKeywords = this.topKeywords.filter((keyword) => keyword.selected)
                    return selectedtopKeywords
                },
                set(val) {
                    console.log("val", val)
                }
            }
        },

        methods: {
            getWDFIDF(item) {
                return item.avgWDF ? (item.avgWDF * item.idf.idf).toFixed(3) : "-"
            },
            customSort(items, index, isDesc) {
                let sortedItems = this.$helpers.globalSort(items, index, isDesc, this.headers, this.relevanceData)
                console.log(sortedItems)
                return sortedItems
            },
            async calcRelevanceData(keywords) {
                // Get min and max Value of all Keywords for normalizing
                this.relevanceData = await this.$helpers.getRelevanceMinMaxValues(keywords)
            },
            getRelevance(item) {
                const urlCovWeight = 0.15
                const wdfWeight = 0.6
                const termWeight = 0.25
                let normURLCov = item.url_coverage
                let normTerm = (item.termplacementValue - this.relevanceData.minPlacementValue) / (this.relevanceData.maxPlacementValue - this.relevanceData.minPlacementValue)
                normTerm = normTerm > 0 ? Math.min(...[normTerm + 0.52, 1]) : 0 // every keyword with any placement starts at 52 percent

                let normWDF = (item.avgWDF - this.relevanceData.minWDF) / (this.relevanceData.maxWDF - this.relevanceData.minWDF)

                let sum = normURLCov * urlCovWeight + normTerm * termWeight + normWDF * wdfWeight
                return { value: Math.ceil(sum * 100) }
            },
            getPercent(value) {
                return parseInt(value * 100)
            },
            selectItem(index, item) {
                let visibleIndex = this.topKeywords.indexOf(item)
                if (!this.topKeywords[visibleIndex].selected) {
                    this.topKeywords[visibleIndex].selected = false
                }
                this.topKeywords[visibleIndex].selected = !this.topKeywords[visibleIndex].selected
                this.$emit("updateKeywords", [item])
            },
            selectPage(event) {
                console.log(event.items)
                let kwToUpdate = []
                let select = false
                let selectedPageItems = event.items.filter((x) => x.selected)
                if (event.value && selectedPageItems.length == 0) {
                    select = true
                }
                event.items.map((item) => {
                    let kw = this.topKeywords.find((keyword) => keyword.keyword === item.keyword)
                    if (kw) {
                        kw.selected = select
                        kwToUpdate.push(kw)
                    }
                })
                this.$emit("updateKeywords", kwToUpdate)

                // let selectedPageItems = event.items.filter((x) => x.selected)
                // if (event.value && selectedPageItems.length == 0) {
                //     //select

                //     event.items.map((x) => {
                //         x.selected = true
                //         this.selected.push(x)
                //     })
                // } else {
                //     //unselect
                //     event.items.map((x) => {
                //         const index = this.selected.findIndex((selectedRow) => selectedRow.keyword == x.keyword)
                //         if (index > -1) {
                //             this.selected.splice(index, 1)
                //         }
                //         x.selected = false
                //     })
                // }
            }
        },
        mounted() {
            if (this.topKeywords && this.topKeywords.length) {
                this.calcRelevanceData(this.topKeywords)
            }
        }
    }
</script>

<style scoped>
    .loaderwrapper {
        padding: 50px;
    }

    .loaderimg {
        margin: auto;
    }
    #loader {
        margin: auto;
    }
    .comp-table-area {
        /* background: #f8f8f8 !important; */
        border-radius: 5px;
    }
    .td_keyword {
        font-size: 16px;
        /* font-weight: bold; */
    }
    .comp-table {
    }
</style>
