<template>
    <div class="mt-5">
        <!-- Info -->
        <v-snackbar top :color="info.color" v-model="info.show">
            <v-row>
                <v-col style="max-width: 50px">
                    <v-icon>{{ info.icon }}</v-icon>
                </v-col>
                <v-col>
                    <div>
                        <span class="font-weight-bold">{{ info.message }}</span>
                    </div>
                    <div v-if="info.note" class="note">{{ info.note }}</div>
                </v-col>
            </v-row>
        </v-snackbar>
        <!-- <div>
            <v-btn>AI Content Generator</v-btn>
            <BriefingOutline v-if="" :project="project" :shared_extern="false"></BriefingOutline>
        </div> -->
        <div class="staticMenuWrapper" v-if="editor && !activeCommentThread">
            <div style="display: inline-flex; margin: auto; position: relative">
                <div class="staticMenu">
                    <v-menu offset-y content-class="submenu">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn id="aifuncbtn" v-bind="attrs" v-on="on" class="white--text" color="purple darken-2" text>
                                <v-icon size="16px" class="mr-1">mdi-creation-outline</v-icon>
                                AI Tools
                                <v-icon class="pl-1" size="10px">mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list rounded dense>
                            <v-subheader style="text-transform: uppercase; font-size: 11px">Generate Content </v-subheader>

                            <!-- <v-list-item disabled id="floatingMenuList" tabindex="1" @click="addAiOutlineGenerator">
                                <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-head-remove-outline</v-icon>
                                <v-list-item-title class="font-medium text-sm">AI Headlines</v-list-item-title>
                            </v-list-item> -->
                            <v-list-item tabindex="3" id="floatingMenuList" @click="addAiWriter">
                                <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-creation-outline</v-icon>
                                <v-list-item-title class="font-medium text-sm">AI Writer</v-list-item-title>
                            </v-list-item>
                            <!-- <v-list-item @click="generateContentOpenAI">
                                <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-chevron-double-right</v-icon>
                                <v-list-item-title>Generate Text - openAI</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="stopGenerateContentOpenAI">
                                <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-chevron-double-right</v-icon>
                                <v-list-item-title>STOP!!!!</v-list-item-title>
                            </v-list-item> -->

                            <v-subheader style="text-transform: uppercase; font-size: 11px">Change Content </v-subheader>
                            <v-menu offset-x content-class="submenu" max-height="350px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item @click="" v-on="on">
                                        <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-translate</v-icon>
                                        <v-list-item-title>Translate</v-list-item-title>
                                        <v-icon color="grey darken-1" size="14px">mdi-chevron-right</v-icon>
                                    </v-list-item>
                                </template>
                                <v-list rounded dense>
                                    <v-list-item v-for="language in languages" :key="language" @click="translate(language)">
                                        <v-list-item-title>{{ language }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-list-item @click="makeLonger">
                                <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-chevron-double-right</v-icon>
                                <v-list-item-title>Make longer</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="makeShorter">
                                <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-chevron-double-left</v-icon>
                                <v-list-item-title>Make shorter</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="paraphrase">
                                <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-refresh</v-icon>
                                <v-list-item-title>Paraphrase</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="endSentence">
                                <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-progress-pencil </v-icon>
                                <v-list-item-title>Complete sentence</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="fixSpelling">
                                <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-spellcheck </v-icon>
                                <v-list-item-title>Fix spelling & grammar</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <div class="menu-vl"></div>

                    <v-btn class="hidden-sm-and-down" style="" text @click="scrollTo(true)">
                        <v-icon color="grey darken-1" size="16px">mdi-mouse-outline</v-icon>
                        <v-icon color="grey darken-1" size="12px">mdi-chevron-down</v-icon>
                    </v-btn>
                    <v-btn class="hidden-sm-and-down" text @click="scrollTo(false)"
                        ><v-icon color="grey darken-1" class="px-0" size="16px">mdi-mouse-outline</v-icon>
                        <v-icon class="pl-0" color="grey darken-1" size="12px">mdi-chevron-up</v-icon>
                    </v-btn>

                    <v-btn class="hidden-sm-and-down" text @click="editor.chain().focus().undo().run()" :disabled="!editor.can().undo()"
                        ><v-icon color="grey darken-1" class="px-0" size="20px">mdi-undo-variant</v-icon></v-btn
                    >
                    <v-btn class="hidden-sm-and-down" text @click="editor.chain().focus().redo().run()" :disabled="!editor.can().redo()"
                        ><v-icon color="grey darken-1" class="px-0" size="20px">mdi-redo-variant</v-icon></v-btn
                    >
                    <v-btn text @click="saveContentManually"><v-icon color="grey darken-1" class="px-0" size="20px">mdi-content-save-outline</v-icon></v-btn>

                    <div class="menu-vl"></div>
                    <v-menu bottom offset-y right content-class="submenu">
                        <template v-slot:activator="{ on }">
                            <v-btn class="" text v-on="on">
                                <v-icon color="grey darken-3" size="20px">mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list rounded class="menu-list" dense style="max-height: 300px; overflow-y: auto">
                            <v-list-item @click="exportContent({ json: false })">
                                <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-language-html5</v-icon>
                                <v-list-item-title>export HTML</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="exportContent({ json: true })">
                                <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-code-json</v-icon>
                                <v-list-item-title>export JSON</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="scrollTo(true)" class="hidden-md-and-up">
                                <v-icon color="grey darken-1" size="16px">mdi-mouse-outline</v-icon>
                                <v-icon class="mr-2" color="grey darken-1" size="12px">mdi-chevron-down</v-icon>
                                <v-list-item-title>scroll down</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="scrollTo(false)" class="hidden-md-and-up">
                                <v-icon color="grey darken-1" class="px-0" size="16px">mdi-mouse-outline</v-icon>
                                <v-icon class="mr-2 pl-0" color="grey darken-1" size="12px">mdi-chevron-up</v-icon>
                                <v-list-item-title>scroll up</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="editor.chain().focus().undo().run()" :disabled="!editor.can().undo()" class="hidden-md-and-up">
                                <v-icon color="grey darken-1" class="mr-2" size="20px">mdi-undo-variant</v-icon>
                                <v-list-item-title>undo</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="editor.chain().focus().redo().run()" :disabled="!editor.can().redo()" class="hidden-md-and-up">
                                <v-icon color="grey darken-1" class="mr-2" size="20px">mdi-redo-variant</v-icon>
                                <v-list-item-title>redo</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <div style="width: calc(100% + 16px); position: absolute; height: 2px; background-color: white; top: -8px; left: -8px; z-index: 2"></div>
                    <div style="width: calc(100% + 4px); position: absolute; height: 8px; background-color: white; top: -8px; left: -2px; z-index: 2"></div>
                    <div style="position: relative; width: 100%; height: 100%" v-if="aiLoading">
                        <v-progress-linear
                            rounded
                            height="3px"
                            style="border-radius: 0px 0px 10px 10px; width: 394px; position: absolute; top: 17px; right: -3px; z-index: 9999"
                            indeterminate
                            color="primary"></v-progress-linear>
                    </div>
                </div>
            </div>

            <!-- <div>d</div> -->
        </div>
        <floating-menu id="floatingMenu" class="floating-menu" :tippy-options="{ duration: 100 }" :editor="editor" v-if="editor && showFloating">
            <!-- <button @click="convertToHeading('heading', { level: 2 })" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">H1</button>
            <button @click="convertToHeading('heading', { level: 2 })" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">H2</button>
            <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">Bullet List</button> -->

            <v-menu bottom offset-y content-class="submenu" v-model="floating" disable-keys>
                <template v-slot:activator="{ on }">
                    <v-row>
                        <v-btn icon v-on="on">
                            <v-icon color="grey darken-1" size="20px" class="">mdi-plus</v-icon>
                        </v-btn>
                        <span style="padding-top: 7px !important"> Type / to browse options</span></v-row
                    >
                </template>
                <v-list id="floatingmenu" class="menu-list" rounded dense style="max-height: 300px; overflow-y: auto">
                    <v-subheader>AI </v-subheader>
                    <!-- <v-list-item disabled id="floatingMenuList" tabindex="1" @click="addAiOutlineGenerator">
                        <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-head-remove-outline</v-icon>
                        <v-list-item-title class="font-medium text-sm">AI Headlines</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item tabindex="3" id="floatingMenuList" @click="addAiWriter">
                        <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-creation-outline</v-icon>
                        <v-list-item-title class="font-medium text-sm">AI Writer</v-list-item-title>
                    </v-list-item>
                    <v-subheader>FORMAT</v-subheader>
                    <v-list-item tabindex="3" @click="editor.chain().focus().setParagraph().run()">
                        <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-format-paragraph</v-icon>
                        <v-list-item-title class="font-medium text-sm">Paragraph</v-list-item-title>
                    </v-list-item>
                    <v-list-item tabindex="4" @click="convertToHeading('heading', { level: 1 })">
                        <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-format-header-1</v-icon>
                        <v-list-item-title>Heading 1</v-list-item-title>
                    </v-list-item>
                    <v-list-item tabindex="5" @click="convertToHeading('heading', { level: 2 })">
                        <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-format-header-2</v-icon>
                        <v-list-item-title>Heading 2</v-list-item-title>
                    </v-list-item>
                    <v-list-item tabindex="6" @click="convertToHeading('heading', { level: 3 })">
                        <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-format-header-3</v-icon>
                        <v-list-item-title>Heading 3</v-list-item-title>
                    </v-list-item>
                    <v-list-item tabindex="7" @click="editor.chain().focus().toggleBulletList().run()">
                        <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-format-list-bulleted</v-icon>
                        <v-list-item-title>Bullet List</v-list-item-title>
                    </v-list-item>
                    <v-list-item tabindex="8" @click="editor.chain().focus().toggleOrderedList().run()">
                        <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-format-list-numbered</v-icon>
                        <v-list-item-title>Numbered List</v-list-item-title>
                    </v-list-item>
                    <v-list-item tabindex="9" @click="editor.chain().focus().toggleCodeBlock().run()">
                        <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-code-tags</v-icon>
                        <v-list-item-title>Code Block</v-list-item-title>
                    </v-list-item>
                    <v-subheader>INSERT</v-subheader>
                    <v-list-item tabindex="10" @click="insertTable">
                        <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-table</v-icon>
                        <v-list-item-title>Table</v-list-item-title>
                    </v-list-item>

                    <v-menu bottom offset-x right content-class="submenu" :close-on-content-click="false" v-model="imageMenu">
                        <template v-slot:activator="{ on }">
                            <v-list-item tabindex="11" v-on="on">
                                <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-image</v-icon>
                                <v-list-item-title>Image</v-list-item-title>
                                <v-icon color="grey darken-1" size="14px">mdi-chevron-right</v-icon>
                            </v-list-item>
                        </template>
                        <v-card max-width="700px">
                            <v-card-text class="pb-0">
                                <v-row no-gutters>
                                    <v-col>
                                        <v-text-field v-model="imageURL" max-width="500px" dense rounded filled hide-details placeholder="Enter Image URL" prepend-inner-icon="mdi-web"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field rounded filled dense hide-details v-model="imageTitle" placeholder="Title"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field rounded filled dense hide-details v-model="imageAlt" placeholder="Alt"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="pt-0">
                                <v-spacer></v-spacer>
                                <v-btn rounded color="primary" class="ml-2" style="margin-top: 2px" @click="addImage">OK</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                    <v-menu bottom offset-x right content-class="submenu" :close-on-content-click="false" v-model="videoMenu">
                        <template v-slot:activator="{ on }">
                            <v-list-item tabindex="12" v-on="on">
                                <v-icon class="mr-3" color="grey darken-1" size="20px">mdi-video</v-icon>
                                <v-list-item-title>Video</v-list-item-title>
                                <v-icon color="grey darken-1" size="14px">mdi-chevron-right</v-icon>
                            </v-list-item>
                        </template>
                        <v-card max-width="700px">
                            <v-card-text class="pb-0">
                                <v-row no-gutters>
                                    <v-col>
                                        <v-text-field
                                            v-model="videoURL"
                                            max-width="500px"
                                            dense
                                            rounded
                                            filled
                                            hide-details
                                            placeholder="enter youtube URL"
                                            prepend-inner-icon="mdi-web"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field suffix="px" rounded filled dense hide-details v-model="videoWidth" placeholder="Width"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field suffix="px" rounded filled dense hide-details v-model="videoHeight" placeholder="Height"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="pt-0">
                                <v-spacer></v-spacer>
                                <v-btn rounded color="primary" class="ml-2" style="margin-top: 2px" @click="addVideo">OK</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-list>
            </v-menu>
        </floating-menu>
        <bubble-menu v-if="editor" class="quaro-bubble-menu" :editor="editor" :tippy-options="{ duration: 100 }" id="link-bubble-menu" :shouldShow="linkActive">
            <div id="link-bubble-menu-wrapper" class="pa-2">
                <v-row no-gutters style="width: 100%">
                    <v-text-field v-model="linkURL" dense rounded filled hide-details placeholder="Enter Link URL" prepend-inner-icon="mdi-link"></v-text-field>
                    <v-btn @click="removeLink" color="primary" class="my-auto ml-1" small icon>
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </v-row>
                <v-row class="pl-1" no-gutters style="width: 100%">
                    <v-col>
                        <v-switch class="mt-2" dense inset hide-details label="open in tab" v-model="linkNewTab"></v-switch>
                    </v-col>
                    <v-col>
                        <v-switch class="mt-2" dense inset hide-details label="follow" v-model="linkFollow"></v-switch>
                    </v-col>
                    <v-col class="d-flex" style="max-width: 50px">
                        <v-btn rounded style="background-color: #00000012 !important" class="mt-auto" color="primary" small text @click="addLink">Ok</v-btn>
                    </v-col>
                </v-row>
            </div>
        </bubble-menu>
        <!-- ----------  -->
        <!-- Image Menu  -->
        <!-- ----------  -->
        <bubble-menu v-if="editor" class="quaro-bubble-menu" :editor="editor" :tippy-options="{ duration: 100 }" id="image-bubble-menu" :shouldShow="imageActive">
            <div>
                <v-btn text @click="alignImage('left')">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="grey darken-1" size="20px">mdi-format-horizontal-align-left</v-icon>
                        </template>
                        Align Left
                    </v-tooltip>
                </v-btn>
                <v-btn text @click="alignImage('center')">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="grey darken-1" size="20px">mdi-format-horizontal-align-center</v-icon>
                        </template>
                        Align Center
                    </v-tooltip>
                </v-btn>
                <v-btn text @click="alignImage('right')">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="grey darken-1" size="20px">mdi-format-horizontal-align-right</v-icon>
                        </template>
                        Align Right
                    </v-tooltip>
                </v-btn>

                <div class="menu-vl"></div>
                <div class="d-flex px-2" style="width: 150px">
                    <v-slider
                        step="10"
                        ticks
                        v-model="imageSizeSlider"
                        hide-details
                        @mouseup="
                            editor.chain().focus().run()
                            imageResize = true
                            setImageSize()
                        "></v-slider>
                    <!-- <v-slider
                        step="10"
                        ticks
                        v-model="imageSize"
                        hide-details
                        @change="editor.chain().focus().run()"
                        @mouseup="editor.chain().focus().run()"
                        @mousedown="editor.chain().focus().run()"
                        @click="editor.chain().focus().run()"></v-slider> -->

                    <span class="my-auto"> {{ imageSizeSlider }} % </span>
                </div>
                <div class="menu-vl"></div>
                <v-btn text @click="deleteImage()">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="grey darken-1" size="20px">mdi-image-remove-outline</v-icon>
                        </template>
                        Delete Image
                    </v-tooltip>
                </v-btn>
            </div>
        </bubble-menu>
        <!-- ---------  -->
        <!-- Main Menu  -->
        <!-- ---------  -->
        <bubble-menu class="quaro-bubble-menu" :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor" id="formatting-bubble-menu">
            <div v-if="(!imageActiveComp && !tableActive && !this.editor.isActive('vueComponent')) || showMainMenu" class="mainmenu">
                <v-menu offset-y content-class="submenu" v-model="aiMenu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn id="aifuncbtn" v-bind="attrs" v-on="on" class="white--text" color="purple darken-2" text>
                            <v-icon x-small class="mr-1">mdi-creation-outline</v-icon>
                            AI Tools
                            <v-icon class="pl-1" size="10px">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list rounded dense>
                        <v-menu offset-x content-class="submenu" max-height="350px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item class="pl-2" @click="" v-on="on">
                                    <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-translate</v-icon>
                                    <v-list-item-title>Translate</v-list-item-title>
                                    <v-icon color="grey darken-1" size="14px">mdi-chevron-right</v-icon>
                                </v-list-item>
                            </template>
                            <v-list rounded dense>
                                <v-list-item class="pl-2" v-for="language in languages" :key="language" @click="translate(language)">
                                    <v-list-item-title>{{ language }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-list-item class="pl-2" @click="makeLonger">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-chevron-double-right</v-icon>
                            <v-list-item-title>Make longer</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="pl-2" @click="makeShorter">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-chevron-double-left</v-icon>
                            <v-list-item-title>Make shorter</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="pl-2" @click="paraphrase">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-refresh</v-icon>
                            <v-list-item-title>Paraphrase</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="pl-2" @click="endSentence">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-progress-pencil </v-icon>
                            <v-list-item-title>Complete sentence</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="pl-2" @click="fixSpelling">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-spellcheck </v-icon>
                            <v-list-item-title>Fix spelling & grammar</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <div class="menu-vl"></div>
                <!-- Dropdown menu for Headings -->
                <v-menu bottom offset-y content-class="submenu">
                    <template v-slot:activator="{ on }">
                        <v-btn class="" text v-on="on">
                            <v-icon color="grey darken-1" size="20px">{{ getCurrentHierarchy() || "mdi-format-header-pound" }}</v-icon>
                            <v-icon color="grey darken-1" class="pl-1" size="10px">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="menu-list" rounded dense style="max-height: 300px; overflow-y: auto">
                        <v-subheader>HIERARCHY</v-subheader>
                        <v-list-item @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-format-paragraph</v-icon>
                            <v-list-item-title>Paragraph</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="convertToHeading('heading', { level: 1 })" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-format-header-1</v-icon>
                            <v-list-item-title>Heading 1</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="convertToHeading('heading', { level: 2 })" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-format-header-2</v-icon>
                            <v-list-item-title>Heading 2</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="convertToHeading('heading', { level: 3 })" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-format-header-3</v-icon>
                            <v-list-item-title>Heading 3</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="convertToHeading('heading', { level: 4 })" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-format-header-4</v-icon>
                            <v-list-item-title>Heading 4</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="convertToHeading('heading', { level: 5 })" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-format-header-5</v-icon>
                            <v-list-item-title>Heading 5</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="convertToHeading('heading', { level: 6 })" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-format-header-6</v-icon>
                            <v-list-item-title>Heading 6</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu offset-y content-class="submenu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="" text v-on="on">
                            {{ getCurrentFont() || defaultFont }}
                            <v-icon color="grey darken-1" class="pl-1" size="10px">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="menu-list" rounded dense style="max-height: 300px; overflow-y: auto">
                        <v-subheader>Serif Fonts</v-subheader>

                        <v-list-item
                            v-for="fontFamily in serifFonts"
                            :key="fontFamily"
                            :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: fontFamily }) }"
                            @click="changeFontFamily(fontFamily)">
                            <v-list-item-title :style="{ 'font-family': fontFamily }">{{ fontFamily }}</v-list-item-title>
                        </v-list-item>

                        <v-subheader>Sans-serif Fonts</v-subheader>

                        <v-list-item
                            v-for="fontFamily in sansSerifFonts"
                            :key="fontFamily"
                            :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: fontFamily }) }"
                            @click="changeFontFamily(fontFamily)">
                            <v-list-item-title :style="{ 'font-family': fontFamily }">{{ fontFamily }}</v-list-item-title>
                        </v-list-item>

                        <v-subheader>Monospace Fonts</v-subheader>

                        <v-list-item
                            v-for="fontFamily in monospaceFonts"
                            :key="fontFamily"
                            :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: fontFamily }) }"
                            @click="changeFontFamily(fontFamily)">
                            <v-list-item-title :style="{ 'font-family': fontFamily }">{{ fontFamily }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu offset-y flat content-class="submenu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="" text v-on="on">
                            {{ getCurrentFontSize() || defaultFontSize }}
                            <v-icon color="grey darken-1" class="pl-1" size="10px">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="menu-list" dense style="max-height: 300px; overflow-y: auto">
                        <v-subheader>FONT SIZE</v-subheader>
                        <v-list-item
                            style="border-radius: 10px !important"
                            v-for="fontSize in fontSizes"
                            :key="fontSize"
                            class="pl-2"
                            :class="{ 'is-active': editor.isActive('textStyle', { fontSize: fontSize }) }"
                            @click="changeFontSize(fontSize)">
                            <v-list-item-title>{{ fontSize }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <div class="menu-vl"></div>
                <v-btn style="" text @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                    <v-icon color="grey darken-1" size="20px">mdi-format-bold</v-icon></v-btn
                >
                <v-btn text @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }"
                    ><v-icon color="grey darken-1" class="px-0" size="20px">mdi-format-italic</v-icon></v-btn
                >
                <v-btn text @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }"
                    ><v-icon color="grey darken-1" class="px-0" size="20px">mdi-format-underline</v-icon></v-btn
                >
                <!-- <v-btn text @click="editor.chain().focus().indent().run()" :class="{ 'is-active': editor.isActive('underline') }"
                    ><v-icon color="grey darken-1" class="px-0" size="20px">mdi-format-indent-increase</v-icon></v-btn
                > -->
                <v-btn text @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }"
                    ><v-icon color="grey darken-1" size="20px">mdi-format-strikethrough </v-icon></v-btn
                >
                <v-btn text @click="setComment"><v-icon color="grey darken-1" size="20px">mdi-comment-plus</v-icon></v-btn>
                <v-menu bottom offset-y right content-class="submenu">
                    <template v-slot:activator="{ on }">
                        <v-btn class="" text v-on="on">
                            <v-icon color="grey darken-1" size="20px">{{ getCurrentAlignment() || "mdi-format-align-left" }}</v-icon>
                            <v-icon color="grey darken-1" class="pl-1" size="10px">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="menu-list" dense style="max-height: 300px; overflow-y: auto">
                        <v-subheader>ALIGNMENT</v-subheader>
                        <v-list-item
                            v-for="alignment in alignments"
                            :key="alignment.type"
                            @click="editor.chain().focus().setTextAlign(alignment.type).run()"
                            :class="{ 'is-active': editor.isActive({ textAlign: alignment.type }) }">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">{{ alignment.icon }}</v-icon>
                            <v-list-item-title style="text-transform: capitalize">{{ alignment.type }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-btn text @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }"
                    ><v-icon color="grey darken-1" size="20px">mdi-format-list-bulleted</v-icon></v-btn
                >
                <v-btn text @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }"
                    ><v-icon color="grey darken-1" size="20px">mdi-format-list-numbered</v-icon></v-btn
                >
                <v-menu bottom offset-y right content-class="submenu" v-model="pickermenu">
                    <template v-slot:activator="{ on }">
                        <v-btn class="" text v-on="on">
                            <v-icon color="grey darken-1" size="20px">mdi-palette</v-icon>
                        </v-btn>
                    </template>
                    <v-card class="pa-1">
                        <v-color-picker
                            content-class="editor-color-picker"
                            dot-size="25"
                            :swatches="colorSwatches"
                            show-slider
                            show-swatches
                            v-model="activeColor"
                            :value="defaultColor"></v-color-picker>
                    </v-card>
                </v-menu>
                <!-- <v-btn text @click="editor.chain().focus().toggleImage({ src: 'url-to-image' }).run()" :class="{ 'is-active': editor.isActive('image') }"
                    ><v-icon color="grey darken-1" size="20px">mdi-image</v-icon></v-btn
                > -->

                <v-menu bottom offset-y right content-class="submenu" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <!-- @click="editor.chain().focus().toggleLink({ href: 'url-to-link' }).run()" -->
                        <v-btn @click="linkURL = editor.getAttributes('link').href" v-on="on" text :class="{ 'is-active': editor.isActive('link') }"
                            ><v-icon color="grey darken-1" size="20px">mdi-link</v-icon></v-btn
                        >
                    </template>
                    <v-card width="450px">
                        <div class="pa-2">
                            <v-row no-gutters style="width: 100%">
                                <v-text-field v-model="linkURL" dense rounded filled hide-details placeholder="Enter Link URL" prepend-inner-icon="mdi-link"></v-text-field>
                                <!-- <v-btn color="primary" class="my-auto ml-1" small icon>
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn> -->
                            </v-row>
                            <v-row class="pl-1" no-gutters style="width: 100%">
                                <v-col>
                                    <v-switch class="mt-2" dense inset hide-details label="open in tab" v-model="linkNewTab"></v-switch>
                                </v-col>
                                <v-col>
                                    <v-switch class="mt-2" dense inset hide-details label="follow" v-model="linkFollow"></v-switch>
                                </v-col>
                                <v-col class="d-flex" style="max-width: 50px">
                                    <v-btn rounded style="background-color: #00000012 !important" class="mt-auto" color="primary" small text @click="addLink">Ok</v-btn>
                                </v-col>
                            </v-row>
                        </div>

                        <!-- <v-row no-gutters>
                                <v-col>
                                    <v-text-field v-model="linkURL" max-width="500px" dense rounded filled hide-details placeholder="Enter Link URL" prepend-inner-icon="mdi-web"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                    <v-switch hide-details label="Open in new tab" v-model="linkNewTab"></v-switch>
                                </v-col>
                                <v-col>
                                    <v-switch hide-details label="Follow" v-model="linkFollow"></v-switch>
                                </v-col>
                            </v-row> -->
                        <!-- <v-card-actions class="pt-0">
                            <v-spacer></v-spacer>
                            <v-btn rounded color="primary" class="ml-2" style="margin-top: 2px" @click="addLink">OK</v-btn>
                        </v-card-actions> -->
                    </v-card>
                </v-menu>
                <v-btn text @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }"
                    ><v-icon color="grey darken-1" size="20px">mdi-code-tags</v-icon></v-btn
                >

                <div class="menu-vl"></div>
                <v-menu bottom offset-y right content-class="submenu">
                    <template v-slot:activator="{ on }">
                        <v-btn class="" text v-on="on">
                            <v-icon color="grey darken-3" size="20px">mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="menu-list" dense style="max-height: 300px; overflow-y: auto">
                        <v-list-item @click="insertTable">
                            <v-icon class="mr-2" color="grey darken-1" size="20px">mdi-table</v-icon>
                            <v-list-item-title>Insert Table</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <!-- ----------  -->
            <!-- Table Menu  -->
            <!-- ----------  -->
            <div v-if="tableActive">
                <v-btn v-if="editor.can().addColumnBefore" text @click="editor.chain().focus().addColumnBefore().run()">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="grey darken-1" size="20px">mdi-table-column-plus-before</v-icon>
                        </template>
                        Add Column Before
                    </v-tooltip>
                </v-btn>

                <v-btn v-if="editor.can().addColumnAfter" text @click="editor.chain().focus().addColumnAfter().run()">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="grey darken-1" size="20px">mdi-table-column-plus-after</v-icon>
                        </template>
                        Add Column After
                    </v-tooltip>
                </v-btn>

                <v-btn v-if="editor.isActive('table') && editor.can().deleteColumn" text @click="editor.chain().focus().deleteColumn().run()">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="red darken-3" size="20px">mdi-table-column-remove</v-icon>
                        </template>
                        Delete Column
                    </v-tooltip>
                </v-btn>
                <div class="menu-vl"></div>
                <v-btn v-if="editor.can().addRowBefore" text @click="editor.chain().focus().addRowBefore().run()">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="grey darken-1" size="20px">mdi-table-row-plus-before</v-icon>
                        </template>
                        Add Row Before
                    </v-tooltip>
                </v-btn>

                <v-btn v-if="editor.can().addRowAfter" text @click="editor.chain().focus().addRowAfter().run()">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="grey darken-1" size="20px">mdi-table-row-plus-after</v-icon>
                        </template>
                        Add Row After
                    </v-tooltip>
                </v-btn>

                <v-btn v-if="editor.can().deleteRow" text @click="editor.chain().focus().deleteRow().run()">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="red darken-3" size="20px">mdi-table-row-remove</v-icon>
                        </template>
                        Delete Row
                    </v-tooltip>
                </v-btn>
                <div class="menu-vl"></div>

                <v-btn v-if="editor.can().toggleHeaderCell" text @click="editor.chain().focus().toggleHeaderCell().run()">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="grey darken-1" size="20px">mdi-table-headers-eye</v-icon>
                        </template>
                        Toggle Header Cell
                    </v-tooltip>
                </v-btn>

                <v-btn v-if="editor.can().mergeOrSplit" text @click="editor.chain().focus().mergeOrSplit().run()">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="grey darken-1" size="20px">mdi-table-split-cell</v-icon>
                        </template>
                        Merge or Split
                    </v-tooltip>
                </v-btn>

                <div class="menu-vl"></div>
                <!-- <v-btn v-if="editor.can().deleteTable" text @click="editor.chain().focus().deleteTable().run()">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="red darken-3" size="20px">mdi-delete-outline</v-icon>
                        </template>
                        Delete Table
                    </v-tooltip>
                </v-btn> -->
                <v-btn text @click="showMainMenu = !showMainMenu" :class="{ 'is-active': showMainMenu }">
                    <v-icon color="grey darken-1" size="20px">mdi-dots-vertical</v-icon>
                    <!-- <v-icon color="grey darken-1" class="pl-0" size="14px">{{ showMainMenu ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon> -->
                </v-btn>
            </div>
            <!-- ----------  -->
            <!-- Link Menu  -->
            <!-- ----------  -->
            <!-- <div v-if="linkActive">HALOOOOOOOOOOOO</div> -->
        </bubble-menu>
        <v-row no-gutters>
            <v-col v-if="activeCommentThread && activeCommentThread.id" style="min-width: 250px !important; max-width: 280px">
                <section style="position: relative; height: 100%; max-height: 80vh; overflow-y: hidden" ref="commentsSectionRef">
                    <template>
                        <v-card flat style="position: sticky; top: 0; z-index: 1">
                            <v-card-title class="pt-0 pl-1" style="font-size: 16px">
                                <v-menu style="border-radius: 25px !important" transition="scale-transition" offset-y offset-x min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn :disabled="$store.state.content_suite_group.id !== 1 && $store.state.content_suite_group.id !== 2" class="px-2" v-on="on" text>
                                            Comment Thread {{ comments.findIndex((c) => c.id == activeCommentThread.id) + 1 }} <v-icon small class="ml-1">mdi-chevron-down</v-icon></v-btn
                                        >
                                    </template>
                                    <v-list dense rounded>
                                        <!-- <v-list-item disabled @click="">
                                <v-icon small class="mr-2">mdi-share</v-icon>
                                <span style="font-size: 12px"> Share Project</span>
                            </v-list-item> -->
                                        <v-list-item @click="unsetComment(activeCommentThread.id)">
                                            <v-icon size="16px" class="mr-1">mdi-delete</v-icon>
                                            <span style="font-size: 12px"> Delete Thread</span>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <!-- Comment Thread {{ comments.findIndex((c) => c.id == activeCommentThread.id) + 1 }} -->
                                <!-- <v-btn @click="closeCommentsSidebar" style="background-color: rgb(246, 246, 246); top: 5px" x-small fab outlined color="primary" absolute right
                                    ><v-icon size="20">mdi-chevron-right</v-icon></v-btn
                                > -->
                                <!-- <v-btn class="ml-auto" @click="unsetComment(activeCommentThread.id)" small color="grey" icon><v-icon size="16px">mdi-delete</v-icon> </v-btn> -->
                                <v-btn class="ml-auto" @click="activeCommentThread = null" small color="grey" icon><v-icon>mdi-chevron-right</v-icon> </v-btn>
                            </v-card-title>
                            <!-- <v-card-subtitle class="pl-1"
                                ><v-chip class="mb-2" small style="font-size: 12px !important; background-color: rgb(246, 246, 246) !important" outlined color="primary">{{
                                    "Created by: " + activeCommentThread.user.username
                                }}</v-chip></v-card-subtitle
                            > -->
                            <v-divider class="mx-auto" style="border-color: rgb(237, 239, 241); border-width: 1px; width: 90%"></v-divider>
                        </v-card>
                        <div style="position: relative" v-if="!activeCommentThread.comments || activeCommentThread.comments.length == 0">
                            <span class="mx-auto" style="position: absolute; top: 35px; left: calc(50% - 60px); color: grey; font-size: 14px">No comments yet...</span>
                        </div>
                        <!-- Chat-Bereich mit Scrollbar -->
                        <v-list ref="commentList" dense class="comment-list" style="overflow-y: auto; flex: 1">
                            <v-list-item v-for="(comment, index) in activeCommentThread.comments" :key="index" class="pl-0 pr-2">
                                <v-avatar
                                    height="32px"
                                    width="32px"
                                    min-width="32px"
                                    style="font-size: 12px"
                                    :style="{ backgroundColor: $helpers.stringToBackgroundColor(comment.user.username) }"
                                    class="comment-avat white--text mr-2"
                                    >{{ $helpers.getInitials(comment.user.username) }}</v-avatar
                                >

                                <div class="comment-area">
                                    <v-list-item-title style="white-space: pre-wrap">
                                        <span class="comment-user">{{ comment.user.username }} </span>
                                        <span class="comment-date">{{ $helpers.formatRelativeDate(comment.date) }}</span>
                                    </v-list-item-title>
                                    <div class="comment-text">{{ comment.content }}</div>
                                </div>
                            </v-list-item>
                        </v-list>
                        <v-divider class="mx-auto" style="border-color: rgb(237, 239, 241); border-width: 1px; width: 90%"></v-divider>
                        <v-list-item class="pl-0 pr-1 mt-auto">
                            <v-textarea class="mt-2" placeholder="Write a comment..." rounded rows="3" hide-details dense filled v-model="commentInput"></v-textarea>
                            <v-btn id="commentSendBtn" class="mt-auto" icon @click="addNewComment" rounded color="primary">
                                <v-icon>mdi-send</v-icon>
                            </v-btn>
                        </v-list-item>
                    </template>
                </section>
            </v-col>
            <v-col>
                <div class="editorwrapper" ref="editorwrapperRef">
                    <!-- <div style="position: relative">
                        <div v-if="aiLoading" class="aioverlay">
                         
                            Ladeanimation...
                        </div>
                    </div> -->
                    <editor-content :editor="editor" ref="editorRef" />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { Editor, EditorContent, BubbleMenu, FloatingMenu } from "@tiptap/vue-2"
    import SetFontSize from "tiptap-extension-font-size"
    import StarterKit from "@tiptap/starter-kit"
    import Bold from "@tiptap/extension-bold"
    import Italic from "@tiptap/extension-italic"
    import BulletList from "@tiptap/extension-bullet-list"
    import CharacterCount from "@tiptap/extension-character-count"
    import CodeBlock from "@tiptap/extension-code-block"
    import TextAlign from "@tiptap/extension-text-align"
    import FontFamily from "@tiptap/extension-font-family"
    import TextStyle from "@tiptap/extension-text-style"
    import Underline from "@tiptap/extension-underline"
    import Youtube from "@tiptap/extension-youtube"
    import Table from "@tiptap/extension-table"
    import TableCell from "@tiptap/extension-table-cell"
    import TableHeader from "@tiptap/extension-table-header"
    import { TableRow } from "@tiptap/extension-table-row"
    import { Color } from "@tiptap/extension-color"
    import Image from "@tiptap/extension-image"
    import Paragraph from "@tiptap/extension-paragraph"
    import { mergeAttributes } from "@tiptap/core"
    import Focus from "@tiptap/extension-focus"
    import Link from "@tiptap/extension-link"
    import Highlight from "@tiptap/extension-highlight"
    import Subscript from "@tiptap/extension-subscript"
    import Superscript from "@tiptap/extension-superscript"
    import FileHandler from "@tiptap-pro/extension-file-handler"
    import { Comment } from "@sereneinserenade/tiptap-comment-extension"
    import { v4 as uuid } from "uuid"
    import aiFunctions from "./aiFunctions"
    import AiWriter from "./CustomExtensions/TipTapAiWriterExtension.js"
    import DraggableItem from "./CustomExtensions/DraggableItem.js"
    import { Extension } from "@tiptap/core"
    import BriefingOutline from "../briefing/BriefingOutline.vue"

    var intervalId = null
    var streamInterval = null

    const CustomTableCell = TableCell.extend({
        renderHTML({ HTMLAttributes }) {
            // Standardmäßiges <td>-Element für alle anderen Zellen
            return ["td", HTMLAttributes, ["span", { class: "grip-column" }], ["p", {}, 0]]
        }
    })
    // const CustomParagraph = Paragraph.extend({
    //     // draggable: true,
    //     parseHTML() {
    //         return [{ tag: "p" }]
    //     },
    //     renderHTML: ({ HTMLAttributes }) => {
    //         return ["div", { "data-type": "draggable-item" }, ["p", HTMLAttributes, 0]]
    //         // return ["dp", { "data-type": "draggable-item" }, ["p", HTMLAttributes, 0]]
    //     }
    // })

    const CustomImage = Image.extend({
        renderHTML({ HTMLAttributes }) {
            const imgAttributes = {
                ...HTMLAttributes
            }
            // Rendere das Bild innerhalb des div-Containers
            return ["div", { class: "d-flex", contenteditable: false }, ["img", imgAttributes]]
        },
        addAttributes() {
            return {
                ...this.parent?.(),
                style: {
                    default: ""
                }
            }
        },
        parseHTML() {
            return [
                {
                    tag: "img[src]", // Akzeptiere nur img-Tags mit der src-Attribute
                    getAttrs: (dom) => ({ src: dom.getAttribute("src") }) // Verwende den src-Attributwert als Quelle
                }
            ]
        }
    })

    export default {
        components: {
            EditorContent,
            BubbleMenu,
            BriefingOutline,
            FloatingMenu
        },
        props: {
            content: String,
            comments: Array,
            pasteContentToEditor: String,
            keywords: Array,
            keywordFrequency: Array,
            project: Object
        },
        data() {
            return {
                aiMenu: false,
                aiLoading: false,
                info: { show: false },
                floating: false,
                // comments: [],
                activeCommentId: {},
                commentsSectionRef: "",
                editor: null,
                showMainMenu: false,
                showImageMenu: true,
                showFloating: true,
                tableMenuPosition: { x: 0, y: 0 },
                defaultColor: "#171717",
                pickermenu: false,
                colorSwatches: [
                    ["#E53935", "#EC407A", "#AB47BC", "#7E57C2", "#5C6BC0"],
                    ["#1E88E5", "#0097A7", "#388E3C", "#FFEE58", "#212121"]
                ],
                alignments: [
                    { type: "left", icon: "mdi-format-align-left" },
                    { type: "right", icon: "mdi-format-align-right" },
                    { type: "justify", icon: "mdi-format-align-justify" },
                    { type: "center", icon: "mdi-format-align-center" }
                ],
                languages: [
                    "English",
                    "Spanish",
                    "French",
                    "German",
                    "Italian",
                    "Portuguese",
                    "Dutch",
                    "Russian",
                    "Chinese (Simplified)",
                    "Chinese (Traditional)",
                    "Japanese",
                    "Korean",
                    "Arabic",
                    "Greek",
                    "Hindi",
                    "Swedish",
                    "Norwegian",
                    "Danish",
                    "Finnish",
                    "Polish",
                    "Czech",
                    "Slovak",
                    "Hungarian",
                    "Romanian",
                    "Bulgarian",
                    "Croatian",
                    "Serbian",
                    "Ukrainian",
                    "Turkish",
                    "Hebrew",
                    "Vietnamese"
                ],
                hierarchies: [
                    { type: "heading1", icon: "mdi-format-header-1" },
                    { type: "heading2", icon: "mdi-format-header-2" },
                    { type: "heading3", icon: "mdi-format-header-3" },
                    { type: "heading4", icon: "mdi-format-header-4" },
                    { type: "heading5", icon: "mdi-format-header-5" },
                    { type: "heading6", icon: "mdi-format-header-6" }
                ],
                defaultFontSize: "14px",
                fontSizes: ["8px", "10px", "12px", "14px", "16px", "18px", "20px", "22px", "24px", "26px", "28px", "30px", "32px"],
                defaultFont: "Arial",
                serifFonts: [
                    "Times New Roman",
                    "Georgia",
                    "Book Antiqua"
                    // Weitere Serif-Schriftarten
                ],
                sansSerifFonts: [
                    "Arial",
                    "Helvetica"
                    // Weitere Sans-serif-Schriftarten
                ],
                monospaceFonts: [
                    "Courier New",
                    "Lucida Console"
                    // Weitere Monospace-Schriftarten
                ],
                videoWidth: 640,
                videoHeight: 480,
                videoURL: "",
                videoMenu: false,
                linkNewTab: true,
                linkURL: "",
                linkFollow: true,
                imageMenu: false,
                imageAlt: "",
                imageTitle: "",
                imageURL: "",
                imageSizeSlider: 100,
                selection: null,
                activeCommentThread: null,
                commentInput: "",
                tipTapContent: "",
                headlineArray: [],
                streamContentArray: [],
                streamActive: false,
                // keywords: ["test1", "test2"]
                backend_socket_id: ""
            }
        },
        updated() {
            // console.log(this.editor.isActive("link"))
        },
        mounted() {
            if (this.content) {
                this.initEditor(this.content)
            }
            this.$socket.on("streamEditorContent", (data) => {
                console.log("new content", data)

                this.streamContentArray.push({ text: data.text, lastElement: data.lastElement })

                // Erster Teil des Texts wird eingefügt
            })
        },

        beforeDestroy() {
            if (this.editor) {
                this.editor.destroy()
                this.editor.view.dom.removeEventListener("keydown", this.handleEnterKey)
            }
        },
        watch: {
            content(val) {
                console.log("watch ini")
                if (!this.editor) {
                    this.initEditor(val)
                }
            },
            pasteContentToEditor(val) {
                console.log(val)
                const selection = this.editor.state.selection
                this.pasteContent(val, selection)
            }
        },
        computed: {
            imageActiveComp() {
                if (!this.editor) return false
                let active = this.editor.isActive("image")
                return active
            },
            tableActive() {
                if (!this.editor) return false
                return this.editor.isActive("table")
            },

            activeColor: {
                set: function (color) {
                    if (this.pickermenu) {
                        this.editor
                            .chain()
                            .focus()
                            .setColor(color.hex ? color.hex : color)
                            .run()
                    }
                },
                get: function () {
                    const attribs = this.editor.getAttributes("textStyle")
                    return attribs && attribs.color ? attribs.color : this.defaultColor
                }
            }
        },
        methods: {
            initEditor(htmlContent) {
                console.log("init Editor")
                // const tempDiv = document.createElement("div")
                // tempDiv.innerHTML = htmlContent

                // const paragraphs = tempDiv.querySelectorAll("p")
                // paragraphs.forEach((paragraph) => {
                //     const images = paragraph.querySelectorAll("img")
                //     images.forEach((image) => {
                //         const parent = image.parentNode
                //         parent.parentNode.insertBefore(image, parent.nextSibling)
                //     })
                // })

                // Das aktualisierte htmlContent-Element mit verschobenen Bildern außerhalb von <p> erstellen
                // htmlContent = tempDiv.innerHTML
                // content: `<p><img  src="https://assets.dm.de/om/review-ui/assets/stars/StarFilled.svg" alt="rating.starFilled"></p><p><img src="https://assets.dm.de/om/review-ui/assets/stars/StarFilled.svg" alt="rating.starFilled"><img src="https://assets.dm.de/om/review-ui/assets/stars/StarFilled.svg" alt="rating.starFilled"><img src="https://assets.dm.de/om/review-ui/assets/stars/StarFilled.svg" alt="rating.starFilled"><img src="https://assets.dm.de/om/review-ui/assets/stars/StarFilled.svg" alt="rating.starFilled"></p>`,

                this.editor = new Editor({
                    content: htmlContent,
                    extensions: [
                        StarterKit,
                        Bold,
                        Italic,
                        BulletList,
                        CharacterCount,
                        CodeBlock,
                        TextAlign.configure({
                            types: ["heading", "paragraph", "table", "image"]
                        }),
                        TextStyle,
                        FontFamily,
                        SetFontSize,
                        Paragraph,
                        Underline,
                        CustomImage,
                        // Image.configure({ inline: true }),
                        // Image.configure({ bubbleMenu: this.imageBubbleMenu }),
                        Youtube,
                        Table.configure({
                            resizable: true
                        }),
                        TableRow,
                        TableHeader,
                        // CustomTableCell,
                        TableCell,
                        Color,
                        Focus,
                        Link.configure({ openOnClick: false }),
                        Highlight,
                        Subscript,
                        Superscript,
                        FileHandler.configure({
                            allowedMimeTypes: ["image/png", "image/jpeg", "image/gif", "image/webp"],
                            onDrop: (currentEditor, files, pos) => {
                                files.forEach((file) => {
                                    const fileReader = new FileReader()
                                    fileReader.readAsDataURL(file)
                                    fileReader.onload = () => {
                                        currentEditor
                                            .chain()
                                            .insertContentAt(pos, {
                                                type: "image",
                                                attrs: {
                                                    src: fileReader.result,
                                                    style: "width: 100%"
                                                    // inline: false
                                                }
                                            })
                                            .focus()
                                            .run()
                                        this.dropImageSave(fileReader.result)
                                    }
                                })
                            },
                            onPaste: (currentEditor, files) => {
                                files.forEach((file) => {
                                    const fileReader = new FileReader()
                                    fileReader.readAsDataURL(file)
                                    fileReader.onload = () => {
                                        currentEditor
                                            .chain()
                                            .insertContentAt(currentEditor.state.selection.anchor, {
                                                type: "image",
                                                attrs: {
                                                    src: fileReader.result,
                                                    style: "width: 100%"
                                                }
                                            })
                                            .focus()
                                            .run()
                                    }
                                })
                            }
                        }),
                        Comment.configure({
                            HTMLAttributes: {
                                class: "my-comment"
                            },
                            onCommentActivated: (commentId) => {
                                this.handleCommentActivation(commentId)
                                // if (commentId) setTimeout(() => this.focusCommentInputWithActiveId(commentId))
                            }
                        }),
                        AiWriter,
                        DraggableItem
                    ],
                    onUpdate: ({ editor }) => {
                        try {
                            const newContent = editor.getHTML()
                            // const wordCount = editor.storage.characterCount.words()
                            console.log(newContent)
                            this.$emit("setContent", newContent)
                            this.$emit("processContent", newContent)
                        } catch (error) {
                            console.log(error)
                            console.log("couldnt get html content") //TODO: add notification
                        }

                        // send the content to an API here
                    }
                })
                this.$emit("processContent", htmlContent)
                this.editor.view.dom.addEventListener("keydown", this.handleEnterKey)
                // this.editor.view.dom.addEventListener("onmousedown", this.setImageSliderValue())
                console.log(htmlContent)
            },
            staticMenuWidth() {
                let element = document.getElementsByClassName("staticMenu")
                if (element && element[0]) {
                    console.log(element)
                    console.log(element.clientWidth)
                }
                return element
            },
            exportContent(options) {
                if (options.json) {
                    // copy json to clipboard
                    const json = this.editor.getJSON()
                    console.log(json)
                    navigator.clipboard.writeText(JSON.stringify(json))
                } else {
                    const html = this.editor.getHTML()
                    navigator.clipboard.writeText(html)
                }
                this.info = this.$helpers.createNotification("Your content has been copied to your clipboard", "mdi-content-copy", "primary")

                // copy html to clipboard
            },
            saveContentManually() {
                this.$emit("processContent")
                //TODO: createNotificationb
                this.info = this.$helpers.createNotification("Editor content successfully saved! ", "mdi-content-save-check-outline", "primary")
                this.info.note = "Note: Your content is saved on every change  automatically."
            },
            linkActive() {
                const { from, to } = this.editor.state.selection
                console.log("active selection", from, to)
                if (this.editor.isActive("link") && from == to) {
                    try {
                        console.log(this.editor.getAttributes("link"))
                        let activelink = this.editor.getAttributes("link")
                        this.linkURL = activelink.href
                        this.linkNewTab = !activelink.target ? false : true
                        console.log("REL", activelink.rel)
                        this.linkFollow = activelink.rel.indexOf("nofollow") == -1 ? true : false
                    } catch (error) {
                        console.log(error)
                    }

                    return true
                }
                return false
            },
            scrollTo(bottom) {
                // Hol dir die Referenz auf den Tiptap-Editor
                var scrollableDiv = document.getElementsByClassName("editorwrapper")
                scrollableDiv = scrollableDiv[0]

                if (bottom) {
                    scrollableDiv.scrollTop = scrollableDiv.scrollHeight
                } else {
                    scrollableDiv.scrollTop = 0
                }
            },
            imageActive() {
                if (!this.editor) return false
                let active = this.editor.isActive("image")
                return active
            },
            handleEnterKey(event) {
                // Überprüfe, ob die gedrückte Taste die Eingabetaste ist (Enter)
                if (event.key === "/") {
                    // Verhindere den Standard-Enter-Verhalten (z. B. Zeilenumbruch)
                    const { state } = this.editor

                    const isInEmptyLine =
                        state.selection.$cursor && state.selection.$cursor.parent.isBlock && state.selection.$cursor.parent.isTextblock && state.selection.$cursor.parent.content.size === 0
                    console.log(isInEmptyLine)
                    if (isInEmptyLine) {
                        event.preventDefault()
                        this.floating = true
                        setTimeout(() => {
                            let element = document.getElementById("floatingMenuList")
                            element.focus()
                            // console.log(element)
                            // element.nextElementByTabIndex.focus()
                        }, 150)
                    }
                    // Füge hier deine gewünschte Logik für die Eingabetaste ein
                }
                if (event.key === "Tab") {
                    event.preventDefault() // Verhindere den Standard-Enter-Verhalten (z. B. Zeilenumbruch)
                    // Aktuelle Cursor-Position
                    if (!this.floating) {
                        const currentPosition = this.editor.state.selection.from

                        // Finde die Startposition des aktuellen Nodes
                        const startOfNode = this.editor.state.doc.resolve(currentPosition).before()

                        // Extrahiere den Text bis zum Anfang des Nodes
                        const textBeforeCursor = this.editor.state.doc.textBetween(startOfNode, currentPosition, "\n")
                        if (textBeforeCursor.length) {
                            this.tabEndSentence(textBeforeCursor, currentPosition)
                        }
                    }
                }
            },
            deleteImage() {
                const { state, commands } = this.editor
                this.editor.chain().focus().deleteRange({ from: state.selection.from, to: state.selection.to }).run()
            },
            addAiWriter() {
                const selection = this.editor.state.selection
                console.log("frequency,", this.keywordFrequency)
                this.editor.commands.insertContentAt(
                    {
                        from: selection.from,
                        to: selection.to
                    },
                    {
                        type: "vueComponent",
                        attrs: {
                            keywords: this.keywords,
                            count: 2,
                            keywordFrequency: this.keywordFrequency
                        }
                    }
                )
            },
            addAiOutlineGenerator() {
                // const selection = this.editor.state.selection
                // const content = `<vue-component count="0"></vue-component>`
                // this.editor.commands.insertContentAt(
                //     {
                //         from: selection.from,
                //         to: selection.to
                //     },
                //     content
                // )
            },
            stopGenerateContentOpenAI() {
                this.$socket.emit("stopContentGeneration", {
                    socket_id: this.$socket.id,
                    backend_socket_id: this.backend_socket_id
                })
                window.clearInterval(streamInterval)
                this.streamContentArray = []
                this.aiLoading = false
                this.streamActive = false
                this.editor.setEditable(true)
            },
            async generateContentOpenAI() {
                try {
                    const assistant = "content_assistant_id"
                    this.aiLoading = true
                    this.aiMenu = false
                    this.editor.setEditable(false)
                    let keywords = []
                    for (let x of this.keywordFrequency) {
                        console.log(this.project?.wdf_version == 2)
                        if (this.project?.wdf_version == 2) {
                            let shortest = x.variants.reduce((shortest, current) => (current.length < shortest.length ? current : shortest))
                            keywords.push(shortest)
                        } else {
                            keywords.push(x.keyword)
                        }
                    }
                    console.log("OUTLINE", this.project)
                    let options = {
                        generation_type: "paragraph",
                        content_type: "Article",
                        topics: this.project.topics
                            .filter((x) => x.selected)
                            .map((k) => k.keyword)
                            .join(","),
                        // headline: "Budgetplanung Messe",
                        outline: this.project.outline,
                        // word_count: this.project.settings.word_count,
                        // tone_of_voice: string, //  the tone of voice the content should be written in (optional)
                        keywords: keywords, // Array of { keyword: string, amount: number } these are the keywords that should be used in the text. Amount specifies how often the respective keywords must be used in the text
                        flesh_reading_ease: this.project.settings.readability.text, // indicates how complicated or simple the text should be written,
                        language_code: this.project.langloc.language_code // in which language the text should be written
                    }
                    console.log(options)
                    this.headlineArray = []
                    let headlines = await this.convertToHeadlineArray(this.project.outline)
                    const response = await aiFunctions.adHocHeadlinesAssistantRun(options, assistant, headlines, this.$socket.id)
                    this.backend_socket_id = response.data.backend_socket_id
                    // console.log("RESPONSE", response)
                    this.startStreamInterval()
                    // this.editor.commands.insertContentAt({ from: 0, to: 1 }, response.data.text, {
                    //     updateSelection: true
                    // })
                    // if (response.data.thread_id && response.data.run_id) {
                    //     this.checkRun(response.data.thread_id, response.data.run_id)
                    // } else {
                    //     this.aiLoading = false
                    // }

                    // console.log(response.data)
                } catch (error) {
                    this.loading = false
                    console.log(error)
                }
            },

            startStreamInterval() {
                streamInterval = setInterval(async () => {
                    console.log("streaminterval")
                    if (this.streamContentArray.length && !this.streamActive) {
                        this.streamActive = true
                        let oldest_content = JSON.parse(JSON.stringify(this.streamContentArray[0]))
                        this.streamContentArray = this.streamContentArray.slice(1)
                        this.streamContent(oldest_content.text)
                        if (oldest_content.lastElement) {
                            window.clearInterval(streamInterval)
                            this.aiLoading = false
                            this.editor.setEditable(true)
                        }
                    }
                }, 500)
            },
            async streamContent(content) {
                console.log("START STREAM PACKET", content)

                // Teile den HTML-Inhalt in vollständige Blöcke (inklusive verschachtelter Tags)
                // const parts = content.match(/<[^>]+>(?:[^<]*|(?:<[^>]+>[^<]*<\/[^>]+>))*<\/[^>]+>|<[^>]+\/?>|[^<]+/g).filter((p) => p.trim().length > 0)
                const parts = this.splitHtmlElements(content)
                console.log("SPLIT PARTS", parts)

                let position = this.editor.state.selection.anchor // Startposition an der aktuellen Cursor-Position

                // Füge die Teile nacheinander ein
                for (let i = 0; i < parts.length; i++) {
                    const currentPart = parts[i]

                    // Prüfen, ob der aktuelle Teil ein <p>-Tag ist und ob es KEINE weiteren HTML-Tags enthält
                    if (currentPart.startsWith("<p>") && currentPart.endsWith("</p>")) {
                        // Entferne die <p>-Tags und prüfe, ob der restliche Inhalt reine Textabsätze ohne weitere HTML-Tags ist
                        const plainText = currentPart.replace(/<\/?p>/g, "").trim()

                        // Wenn keine weiteren HTML-Tags im Paragraphen vorhanden sind, füge ein leeres <p>-Tag ein und dann Wort für Wort den Inhalt
                        if (!plainText.match(/<[^>]+>/)) {
                            // Zuerst ein leeres <p>-Tag einfügen
                            if (i > 0) {
                                if (parts[i - 1].endsWith("</ul>") || parts[i - 1].endsWith("</ol>") || parts[i - 1].endsWith("</li>")) {
                                    console.log("INSERT AFTER LIST")
                                    console.log("POSI", position)
                                    console.log("SIZE", this.editor.state.doc.content.size)
                                    position = position + 3
                                }
                            }
                            this.editor.commands.insertContentAt({ from: position, to: position }, "<p>", { updateSelection: true })

                            // Setze die Position nach dem Öffnen des <p>-Tags
                            position = this.editor.state.selection.anchor // Position innerhalb des eingefügten <p>-Tags ermitteln

                            const words = plainText.split(/\s+/) // Splitte in Wörter

                            // Füge die Wörter nacheinander in das bestehende <p>-Tag ein
                            for (let j = 0; j < words.length; j++) {
                                // Füge jedes Wort einzeln ein
                                this.editor.commands.insertContentAt({ from: position, to: position }, words[j] + " ", { updateSelection: true })
                                this.editor.commands.scrollIntoView()
                                // Aktualisiere die Position, damit das nächste Wort richtig eingefügt wird
                                position = this.editor.state.selection.anchor

                                // Wartezeit von 50ms zwischen den Einfügevorgängen (anpassbar nach Bedarf)
                                await new Promise((resolve) => setTimeout(resolve, 50))
                            }

                            // Aktualisiere die Position nach dem Schließen des <p>-Tags
                            position = this.editor.state.selection.anchor

                            continue // Springe zum nächsten Teil, wenn der aktuelle Teil bereits Wort für Wort eingefügt wurde
                        }
                    }
                    if (i > 0) {
                        if (parts[i - 1].endsWith("</ul>") || parts[i - 1].endsWith("</ol>") || parts[i - 1].endsWith("</li>")) {
                            console.log("INSERT AFTER LIST")
                            position = position + 5
                        }
                    }

                    this.editor.commands.insertContentAt({ from: position, to: position }, currentPart, { updateSelection: true })
                    this.editor.commands.scrollIntoView()
                    // Aktualisiere die Position für den nächsten Block
                    position = this.editor.state.selection.anchor
                }

                this.streamActive = false
            },
            delay(ms) {
                return new Promise((resolve) => setTimeout(resolve, ms))
            },
            convertToHeadlineArray(data) {
                return new Promise((resolve, reject) => {
                    data.forEach(async (item) => {
                        const { tag, text, children } = item
                        if (tag && text) {
                            this.headlineArray.push(`<${tag}>${text}</${tag}>`)
                        }
                        if (children && children.length > 0) {
                            await this.convertToHeadlineArray(children)
                        }
                    })
                    resolve(this.headlineArray)
                })
            },
            splitHtmlElements(htmlString) {
                // DOMParser zum Parsen des HTML-Strings
                const parser = new DOMParser()
                const doc = parser.parseFromString(htmlString, "text/html")

                // Array, um die Elemente zu speichern
                let elementArray = []

                // Rekursive Funktion, um die Elemente zu durchlaufen
                function traverseChildren(node) {
                    const childrenArray = []
                    node.childNodes.forEach((child) => {
                        // Wenn es sich um ein Element handelt, füge es als HTML-String hinzu
                        if (child.nodeType === Node.ELEMENT_NODE) {
                            childrenArray.push(child.outerHTML)
                        }
                    })
                    return childrenArray
                }

                // Start mit dem Body-Element
                elementArray = traverseChildren(doc.body)

                return elementArray
            },
            async makeLonger() {
                if (this.editor) {
                    let textSelection = this.getTextSelection()
                    if (textSelection == "") {
                        this.info = this.$helpers.createNotification("Please select some text to use this function!", "mdi-alert-circle-outline", "primary")
                        return
                    }
                    const message = "Make this text longer: " + textSelection
                    console.time("aifetch")
                    this.aiLoading = true
                    this.aiMenu = false
                    try {
                        const response = await aiFunctions.trainedAssistant(message)
                        this.aiLoading = false
                        console.timeEnd("aifetch")
                        console.log("Completed------------------------------------")
                        console.log(response.data.choices[0].message.content)
                        this.pasteContent(response.data.choices[0].message.content)
                    } catch (error) {
                        console.log(error)
                        this.aiLoading = false
                    }
                }
            },
            async makeShorter() {
                if (this.editor) {
                    let textSelection = this.getTextSelection()
                    if (textSelection == "") {
                        this.info = this.$helpers.createNotification("Please select some text to use this function!", "mdi-alert-circle-outline", "primary")
                        return
                    }
                    const message = "Make this text shorter: " + textSelection
                    console.time("aifetch")
                    this.aiLoading = true
                    this.aiMenu = false
                    try {
                        const response = await aiFunctions.trainedAssistant(message)
                        this.aiLoading = false
                        console.timeEnd("aifetch")
                        console.log("Completed------------------------------------")
                        console.log(response.data.choices[0].message.content)
                        this.pasteContent(response.data.choices[0].message.content)
                    } catch (error) {
                        console.log(error)
                        this.aiLoading = false
                    }
                }
            },
            async translate(language) {
                if (this.editor) {
                    let textSelection = this.getTextSelection()
                    if (textSelection == "") {
                        this.info = this.$helpers.createNotification("Please select some text to use this function!", "mdi-alert-circle-outline", "primary")
                        return
                    }
                    const message = "Translate this text into " + language + ": " + textSelection
                    console.time("aifetch")
                    this.aiLoading = true
                    this.aiMenu = false
                    try {
                        const response = await aiFunctions.trainedAssistant(message)
                        this.aiLoading = false

                        console.timeEnd("aifetch")
                        console.log("Completed------------------------------------")
                        console.log(response.data.choices[0].message.content)
                        this.pasteContent(response.data.choices[0].message.content)
                    } catch (error) {
                        console.log(error)
                        this.aiLoading = false
                    }
                }
            },
            async paraphrase() {
                if (this.editor) {
                    let textSelection = this.getTextSelection()
                    if (textSelection == "") {
                        this.info = this.$helpers.createNotification("Please select some text to use this function!", "mdi-alert-circle-outline", "primary")
                        return
                    }
                    const message = "Paraphrase the following text in the same Language: " + textSelection
                    console.time("aifetch")
                    this.aiLoading = true
                    this.aiMenu = false
                    try {
                        const response = await aiFunctions.trainedAssistant(message)
                        this.aiLoading = false
                        console.timeEnd("aifetch")
                        console.log("Completed------------------------------------")
                        console.log(response.data.choices[0].message.content)
                        this.pasteContent(response.data.choices[0].message.content)
                    } catch (error) {
                        console.log(error)
                        this.aiLoading = false
                    }
                }
            },
            async simplify() {
                if (this.editor) {
                    let textSelection = this.getTextSelection()
                    if (textSelection == "") {
                        this.info = this.$helpers.createNotification("Please select some text to use this function!", "mdi-alert-circle-outline", "primary")
                        return
                    }
                    const message = "Simplify this text: " + textSelection
                    console.time("aifetch")
                    this.aiLoading = true
                    this.aiMenu = false
                    try {
                        const response = await aiFunctions.trainedAssistant(message)
                        this.aiLoading = false
                        console.timeEnd("aifetch")
                        console.log("Completed------------------------------------")
                        console.log(response.data.choices[0].message.content)
                        this.pasteContent(response.data.choices[0].message.content)
                    } catch (error) {
                        console.log(error)
                        this.aiLoading = false
                    }
                }
            },
            async endSentence() {
                if (this.editor) {
                    let textSelection = this.getTextSelection()
                    if (textSelection == "") {
                        this.info = this.$helpers.createNotification("Please select some text to use this function!", "mdi-alert-circle-outline", "primary")
                        return
                    }
                    const message = "End this sentence: " + textSelection
                    console.time("aifetch")
                    this.aiLoading = true
                    this.aiMenu = false

                    try {
                        const response = await aiFunctions.trainedAssistant(message)
                        this.aiLoading = false
                        console.timeEnd("aifetch")
                        console.log("Completed------------------------------------")
                        console.log(response.data.choices[0].message.content)
                        this.pasteContent(response.data.choices[0].message.content)
                    } catch (error) {
                        console.log(error)
                        this.aiLoading = false
                    }
                }
            },
            async tabEndSentence(textSelection, from) {
                console.log("textSelection", textSelection)
                if (textSelection.endsWith(".") || textSelection.endsWith("?") || textSelection.endsWith("!")) {
                    return
                }
                if (this.editor) {
                    const message = "End this sentence (include space as first character if necessary but awnser only the ending): " + textSelection
                    const response = await aiFunctions.trainedAssistant(message)
                    let content = response.data.choices[0].message.content
                    if (content.substring(0, 1) == ".") {
                        content = "."
                    }
                    const characterBefore = this.editor.view.state.doc.textBetween(from - 1, from)
                    // if (characterBefore !== " ") {
                    //     content = " " + content
                    // }
                    this.editor
                        .chain()
                        .focus()
                        .insertContentAt(
                            {
                                from: from
                            },
                            content
                        )
                        .run()
                }
            },
            async fixSpelling() {
                if (this.editor) {
                    let textSelection = this.getTextSelection()
                    if (textSelection == "") {
                        this.info = this.$helpers.createNotification("Please select some text to use this function!", "mdi-alert-circle-outline", "primary")
                        return
                    }
                    const message = "Fix spelling and grammar of this text: " + textSelection
                    console.time("aifetch")
                    this.aiLoading = true
                    this.aiMenu = false
                    try {
                        const response = await aiFunctions.trainedAssistant(message)
                        this.aiLoading = false
                        console.timeEnd("aifetch")
                        console.log("Completed------------------------------------")
                        console.log(response.data.choices[0].message.content)
                        this.pasteContent(response.data.choices[0].message.content)
                    } catch (error) {
                        console.log(error)
                        this.aiLoading = false
                    }
                }
            },

            pasteContent(content, selection = null) {
                if (!selection) {
                    selection = this.selection //TODO: ERROR POSTENTIAL ????
                }
                console.log(selection)
                let paragraphs = content.split("\n\n")
                // Erstelle den HTML-Code für die Tiptap-Komponente
                let contentToPaste = paragraphs
                    .filter((p) => p.length > 0)
                    .map((paragraph) => `<p>${paragraph}</p>`)
                    .join("")
                console.log(contentToPaste)
                this.editor
                    .chain()
                    .focus()
                    .insertContentAt(
                        {
                            from: selection.from - 1,
                            to: selection.to
                        },
                        contentToPaste
                    )
                    .run()
            },
            checkRun(currentThread, currentRun) {
                console.time("aifetch")
                if (currentThread && currentRun) {
                    // intervalId =  setInterval(getRun(), 1000)

                    intervalId = setInterval(async () => {
                        const response = await aiFunctions.checkRun(currentThread, currentRun)
                        if (response.data.status === "completed") {
                            console.timeEnd("aifetch")
                            window.clearInterval(intervalId)
                            try {
                                console.log(response)
                                if (response.data.messages && response.data.messages.data[0]) {
                                    // console.log(response.data.messages.data[0].content[0].text.value)
                                    let contentToPaste = {}
                                    if (response.data.messages.data[0].content[0].text.value.indexOf("```json") > -1) {
                                        contentToPaste = response.data.messages.data[0].content[0].text.value.split("json")[1]
                                        contentToPaste = response.data.messages.data[0].content[0].text.value.split("```")[0]
                                        contentToPaste = JSON.parse(response.data.messages.data[0].content[0].text.value)
                                    } else {
                                        contentToPaste = JSON.parse(response.data.messages.data[0].content[0].text.value)
                                    }

                                    this.editor.commands.insertContentAt({ from: 0, to: 1 }, contentToPaste.content, {
                                        updateSelection: true
                                    })
                                }
                            } catch (error) {
                                console.log(error, response.data.messages.data[0].content[0].text.value)
                            }

                            this.aiLoading = false
                            console.log("RUN-Status: ", response.data)
                        } else {
                            console.log("RUN-Status: ", response.data.status)
                        }
                    }, 1000)
                }
            },
            async getRun() {
                try {
                    console.log(this.currentThread, this.currentRun)

                    const response = await aiFunctions.checkRun(this.currentThread, this.currentRun)
                    if (response.data.status === "completed") {
                        clearInterval(intervalId)
                        console.log("RUN-Status: ", response.data)
                    } else {
                        console.log("RUN-Status: ", response.data.status)
                    }
                } catch (error) {
                    console.log(error)
                    clearInterval(intervalId)
                }
            },
            getTextSelection() {
                this.selection = this.editor.state.selection
                // Hier kannst du auf die Informationen der Selektion zugreifen
                if (!this.selection.empty) {
                    const text = this.editor.view.state.doc.textBetween(this.selection.from, this.selection.to, "\n")
                    // Hier ist der extrahierte Text
                    console.log("text", text)
                    return text
                }
                return ""
            },

            unsetComment(id) {
                this.editor.commands.unsetComment(id)
                this.activeCommentId.value = null
                this.activeCommentThread = null
                //TODO: delete Comment
                this.$emit("deleteComment", id)
            },
            setComment() {
                const newComment = this.getNewThread()

                this.comments.push(newComment)
                this.editor.commands.setComment(newComment.id)
                this.activeCommentId.value = newComment.id
                this.activeCommentThread = this.comments.find((c) => c.id == newComment.id)
                this.$emit("updateComment", this.activeCommentThread)
                setTimeout(() => {
                    const commentList = this.$refs.commentList.$el
                    console.log(commentList)
                    // Scrolle nach unten, um den neuen Kommentar anzuzeigen
                    commentList.scrollTop = commentList.scrollHeight
                    console.log("this.comments", this.comments)
                }, 100)
                setTimeout(() => {
                    const commentInput = document.getElementById("commentSendBtn")
                    console.log("commentInput", commentInput)
                    if (!commentInput) return
                    console.log("scrollToBottom")

                    commentInput.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center"
                    })
                }, 250)
            },
            addNewComment() {
                if (this.commentInput == "") return
                const newC = {
                    id: `a${uuid()}a`,
                    user: {
                        user: this.CryptoJS.AES.decrypt(localStorage.user, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8), // Beispiel-Benutzer-ID
                        username: this.CryptoJS.AES.decrypt(localStorage.username, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) // Beispiel-Benutzername
                    },
                    content: this.commentInput + "",
                    date: new Date()
                }
                this.activeCommentThread.comments.push(newC)
                this.commentInput = ""
                this.$emit("updateComment", this.activeCommentThread)
                setTimeout(() => {
                    console.log("scrollToBottom")
                    const commentList = this.$refs.commentList.$el
                    console.log(commentList)
                    // Scrolle nach unten, um den neuen Kommentar anzuzeigen
                    commentList.scrollTop = commentList.scrollHeight
                    console.log("this.comments", this.comments)
                }, 250)
            },
            getNewThread() {
                return {
                    id: `a${uuid()}a`,
                    user: {
                        user: this.CryptoJS.AES.decrypt(localStorage.user, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8), // Beispiel-Benutzer-ID
                        username: this.CryptoJS.AES.decrypt(localStorage.username, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) // Beispiel-Benutzername
                    },
                    comments: [],
                    createdAt: new Date()
                }
            },
            getNewComment(content) {
                return {
                    id: `a${uuid()}a`,
                    content,
                    replies: [],
                    createdAt: new Date()
                }
            },

            focusCommentInputWithActiveId(id) {
                const commentInput = document.querySelector(`input#${id}`)
                console.log("focusCommentInputWithActiveId", commentInput)
                if (!commentInput) return
                commentInput.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center"
                })
            },

            focusCommentWithActiveId(id) {
                const selector = `.my-comment[data-comment-id="${id}"]`

                const commentElement = document.querySelector(`.my-comment[data-comment-id="${id}"]`)
                if (!commentElement) return
                commentElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center"
                })
            },
            handleCommentActivation(commentId) {
                // if (this.mouseDown) {
                //     return
                // }

                // Behandeln Sie die Aktivierung von Kommentaren in Vue
                if (!commentId) {
                    this.activeCommentId.value = null
                    this.activeCommentThread = null
                    return
                }
                let selectedText = this.getTextSelection()
                if (selectedText.length) {
                    return
                }
                this.activeCommentId.value = commentId
                console.log("handleCommentActivation")
                this.activeCommentThread = this.comments.find((c) => c.id == commentId)
                console.log(this.comments)
                setTimeout(() => {
                    const commentList = this.$refs.commentList.$el
                    console.log(commentList)
                    // Scrolle nach unten, um den neuen Kommentar anzuzeigen
                    commentList.scrollTop = commentList.scrollHeight
                    console.log("this.comments", this.comments)
                }, 100)
                setTimeout(() => {
                    const commentInput = document.getElementById("commentSendBtn")
                    console.log("commentInput", commentInput)
                    if (!commentInput) return
                    console.log("scrollToBottom")

                    commentInput.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center"
                    })
                }, 250)

                // console.log("scrollToBottom")
                // const commentList = this.$refs.commentList.$el
                // console.log(commentList)
                // // Scrolle nach unten, um den neuen Kommentar anzuzeigen
                // commentList.scrollTop = commentList.scrollHeight
            },

            alignImage(align) {
                this.editor.commands.focus()
                this.showImageMenu = false
                console.log(align)
                setTimeout(() => {
                    try {
                        var imageWithFocus = document.querySelector("div.has-focus > img")
                        let styling = imageWithFocus.getAttribute("style")
                        let width = styling.split(";").filter((s) => s.substring(0, 5) == "width")
                        let style = ""
                        if (width) {
                            style += width
                            style += ";"
                        }
                        if (align == "left") {
                            style += "margin-right: auto; margin-left: 0"
                        } else if (align == "right") {
                            style += "margin-right: 0; margin-left: auto"
                        } else {
                            style += "margin-right: auto; margin-left: auto"
                        }
                        imageWithFocus.setAttribute("style", style)

                        this.showImageMenu = true
                    } catch (error) {
                        this.showImageMenu = true
                    }
                }, 250)
            },
            updateImageStyle(editor, attrs) {
                const { selection, tr } = this.editor.view.state
                this.editor.view.dispatch(tr.setNodeMarkup(selection.from, null, { ...this.editor.view.state.doc.nodeAt(selection.from).attrs, style: attrs.style }))
            },
            getImageAttributes() {
                const { selection, doc } = this.editor.view.state
                const node = doc.nodeAt(selection.from)

                if (node && node.type.name === "image") {
                    return node.attrs
                }

                return {}
            },
            setImageSliderValue() {
                let imageAttr = this.getImageAttributes()
                console.log("imageAttr", imageAttr)
                if (imageAttr && imageAttr.style) {
                    console.log(imageAttr.style)
                    let width = imageAttr.style.split(";").find((sa) => sa.trim().substring(0, 5) == "width")
                    console.log(width)
                    if (width.trim().substring(width.trim().length - 1 == "%")) {
                        console.log(parseInt(width.trim().split(":")[1]))
                        this.imageSizeSlider = parseInt(width.trim().split(":")[1])
                    }
                }
                this.imageSizeSlider = 100
            },
            setImageSize() {
                setTimeout(() => {
                    console.log(this.getImageAttributes(), this.imageResize)

                    if (!this.imageResize) return
                    console.log("after")
                    // editor.commands.updateAttributes('heading', { level: 1 })
                    let multiplier = this.imageSizeSlider / 100
                    // this.updateImageStyle(this.$refs.editor, { style: "width: " + size ? size : this.imageSizeSlider + "%" })
                    this.updateImageStyle(this.$refs.editor, { style: "width: " + this.imageSizeSlider + "%" })
                }, 250)
            },
            removeLink() {
                this.editor.chain().focus().extendMarkRange("link").unsetLink().run()
            },
            addLink() {
                console.log("HEEEERE")
                console.log(this.editor.options.extensions)
                console.log("URL", this.linkURL)

                // empty
                if (this.linkURL === "" || !this.linkURL) {
                    this.editor.chain().focus().extendMarkRange("link").unsetLink().run()
                    console.log("RETTTT")
                    return
                }

                // Define the link object and HTMLAttributes
                let link = { href: this.linkURL, target: null }
                let HTMLAttributes = {
                    rel: "noopener noreferrer nofollow" // Default attributes
                }

                // Set target="_blank" if linkNewTab is true
                if (this.linkNewTab) {
                    link.target = "_blank"
                    HTMLAttributes.target = "_blank"
                }

                // Remove nofollow if linkFollow is true
                if (this.linkFollow) {
                    HTMLAttributes.rel = "noopener noreferrer" // Ensure nofollow is not included
                }

                console.log("before setting HTMLAttributes", HTMLAttributes.rel)

                // Directly apply HTMLAttributes when setting the link
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange("link")
                    .setMark("link", {
                        href: link.href,
                        target: link.target,
                        ...HTMLAttributes
                    })
                    .run()

                // this.createLinkeMenuDisabled = true
                // setTimeout(() => {
                //     this.createLinkeMenuDisabled = false
                // }, 500)
            },
            addVideo() {
                this.editor.commands.setYoutubeVideo({
                    src: this.videoURL,
                    width: Math.max(320, parseInt(this.videoWidth, 10)) || 640,
                    height: Math.max(180, parseInt(this.videoHeight, 10)) || 480
                })
                this.videoMenu = false
                this.videoURL = ""
                this.videoWidth = ""
                this.videoHeight = ""
            },
            dropImageSave(src) {
                setTimeout(() => {
                    console.log("process")
                    const newContent = this.editor.getHTML()
                    console.log(newContent)
                    this.$emit("setContent", newContent)
                    this.$emit("processContent")
                }, 1200)
            },
            addImage() {
                this.editor.commands.setImage({ src: this.imageURL, alt: this.imageAlt, title: this.imageTitle })
                this.imageMenu = false
                this.showFloating = false
                this.editor.commands.focus()
                this.imageURL = ""
                this.imageAlt = ""
                this.imageTitle = ""

                setTimeout(() => {
                    this.showFloating = true
                }, 250)
            },
            insertTable() {
                // Annahme: 'editor' ist deine Tiptap-Instanz
                this.editor.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            },
            setTableMenuPosition(event) {
                this.tableMenuPosition = { x: event.clientX, y: event.clientY }
            },
            getCurrentAlignment() {
                let a = null
                this.alignments.map((alignment, index) => {
                    if (this.editor.isActive({ textAlign: alignment.type })) {
                        a = alignment.icon
                    }
                })
                return a
            },
            getCurrentHierarchy() {
                if (this.editor.isActive("paragraph")) {
                    return "mdi-format-paragraph"
                }
                let h = null
                this.hierarchies.map((hierarchy, index) => {
                    if (this.editor.isActive("heading", { level: index + 1 })) {
                        h = hierarchy.icon
                    }
                })
                return h
            },
            getCurrentFont() {
                const attribs = this.editor.getAttributes("textStyle")
                return attribs && attribs.fontFamily ? attribs.fontFamily : this.defaultFont
            },
            getCurrentFontSize() {
                // this.editor.isActive("textStyle", { fontSize: fontSize })
                const attribs = this.editor.getAttributes("textStyle")
                return attribs && attribs.fontSize ? attribs.fontSize : this.defaultFontSize
            },
            changeFontFamily(fontFamily) {
                this.editor.chain().focus().setFontFamily(fontFamily).run()
            },
            changeFontSize(size) {
                this.editor.chain().focus().setFontSize(size).run()
            },
            toggleBold() {
                this.editor.chain().focus().toggleBold().run()
            },
            toggleItalic() {
                this.editor.chain().focus().toggleItalic().run()
            },
            toggleCodeBlock() {
                this.editor.chain().focus().toggleCodeBlock().run()
            },
            // toggleHeading() {
            //     this.convertToHeading('heading', { level: 2 })
            // },
            convertToHeading(type, attribs) {
                const { state } = this.editor
                if (!state.selection.empty) {
                    let selectedText = this.getTextSelection()
                    this.editor.commands.insertContentAt(
                        { from: state.selection.from, to: state.selection.to },
                        [
                            {
                                type: type,
                                attrs: attribs,
                                content: [{ type: "text", text: selectedText }]
                            }
                        ],
                        { updateSelection: true }
                    )
                }
            }
        }
    }
</script>

<style>
    .floating-menu {
        z-index: unset !important;
    }
    /*
    .floating-menu button {
        margin: 5px;
    } */
</style>
<style lang="scss">
    .aioverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }
    @media screen and (max-width: 959px) {
        /* Bei jedem größeren Monitor kommen neue Regeln hinzu */
        .staticMenuWrapper {
            .staticMenu {
                left: -107px !important;
            }
        }
    }
    .staticMenuWrapper {
        display: flex;
        .staticMenu {
            margin: auto;
            margin-top: 8px;
            --tw-bg-opacity: 1;
            background-color: rgb(255 255 255 / var(--tw-bg-opacity));
            --tw-text-opacity: 1;
            color: rgb(115 115 115 / var(--tw-text-opacity));
            // line-height: 1;
            padding: 0.25rem;
            padding-top: 0px;
            --tw-border-opacity: 1;
            border-left: 2px solid #e5e5e5;
            border-right: 2px solid #e5e5e5;
            border-bottom: 2px solid #e5e5e5;
            // border-top: 2px solid white;
            border-radius: 0 0 0.5rem 0.5rem;
            gap: 0.125rem;
            align-items: center;
            flex-direction: row;
            position: absolute;
            left: -199px;

            // background-color: red;
            z-index: 3;
            // height: 100%;
            display: inline-flex;
            &::before {
                content: "";
                position: absolute;
                /* I use this instead of right: 0 to avoid 1px rounding errors */
                left: -8px; /* I use this because I am using left: 100% */
                width: 8px;
                z-index: 3;
                height: 8px;
                border-right: 2px solid #e5e5e5;
                top: -8px;
                transform: rotate(270deg);
                border-bottom: 2px solid #e5e5e5;
                -webkit-border-bottom-right-radius: 16px;
                -khtml-border-bottom-right-radius: 16px;
                -moz-border-radius-bottomright: 16px;
                border-bottom-right-radius: 16px;
            }
            &::after {
                content: "";
                position: absolute;
                transform: rotate(180deg);
                border-right: 2px solid #e5e5e5;
                border-bottom: 2px solid #e5e5e5;
                z-index: 3;

                /* I use this instead of right: 0 to avoid 1px rounding errors */
                right: -8px; /* I use this because I am using left: 100% */
                width: 8px;
                height: 8px;
                // border: 3px solid #f12c2c;
                top: -8px;
                // border-bottom: 3px solid #e5e5e5;
                -webkit-border-bottom-right-radius: 16px;
                -khtml-border-bottom-right-radius: 16px;
                -moz-border-radius-bottomright: 16px;
                border-bottom-right-radius: 16px;
            }
            button {
                min-width: unset !important;
                height: 32px !important;
                padding-left: 10px !important;
                padding-right: 10px !important;
                border-radius: 0.375rem;
                text-transform: unset !important;
            }
        }
    }

    #link-bubble-menu-wrapper {
        flex-direction: column;
        width: 350px !important;
        label {
            font-size: 12px !important;
        }
    }
    .comment-list {
        max-height: calc(80vh - 180px); /* Stelle sicher, dass die Liste die volle Höhe der Sidebar einnimmt */
        min-height: calc(80vh - 180px); /* Stelle sicher, dass die Liste die volle Höhe der Sidebar einnimmt */
        overflow-y: auto; /* Füge eine Scroll-Leiste hinzu, wenn der Chat-Inhalt die Höhe überschreitet */
    }
    .comment-date {
        margin-left: 5px;
        font-style: italic;
        font-size: 12px;
    }
    .comment-user {
        margin-left: 5px;
        font-weight: bold;
        font-size: 12px;
        padding-bottom: 6px;
    }
    .comment-text {
        margin-top: 5px;
        background-color: #ecfef9;
        border-radius: 20px;
        color: #1f1f1f;
        display: inline-block;
        max-width: 100%;
        padding: 10px 12px;
        box-sizing: border-box;
        white-space: pre-wrap;
        position: relative;
        width: fit-content;
        font-size: 0.875rem;
        line-height: 1.25rem;
        word-break: break-word;
        word-wrap: break-word;
    }
    .comment-area {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .editorwrapper {
        scroll-behavior: smooth;
        max-height: 80vh;
        min-height: 80vh;
        overflow-y: auto;
        outline-width: 3;
        outline-color: #e1e1e1 !important;
        border: 2px solid #e1e1e1 !important;
        border-radius: 10px;
    }
    .my-comment {
        border-radius: 4px;
        border-bottom: 2px solid rgb(213, 182, 4);
        background-color: #d9cb525c;
        padding: 0 4px;
    }
    .font-medium {
        font-weight: 500 !important;
    }
    .text-sm {
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
    }
    .ProseMirror > div {
        display: flex !important;
        img {
            &:hover {
                cursor: pointer;
            }
        }
        &.has-focus {
            img {
                border: 2px solid var(--v-primary-base);
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
            }
        }
    }
    // .ProseMirror [data-youtube-video] iframe {
    //     border-radius: 15px;
    //     margin: auto;
    //     &.has-focus {
    //         border: 2px solid var(--v-primary-base);
    //         box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    //     }
    // }
    .editor-color-picker .v-color-picker__controls {
        display: flex !important;
    }

    .v-color-picker__controls {
        display: flex !important;
    }
    // .v-color-picker__swatch {
    //     flex-direction: row !important;
    //     margin-bottom: 0px !important;
    //     padding-bottom: 0px;
    // }

    .tiptap {
        .my-comment {
            position: relative;
            &:hover {
                cursor: pointer;
            }
        }
        // .my-comment::before {
        //     content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(0, 0, 0, 0.209)"><path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M6,7H18V9H6V7M6,11H15V13H6V11Z" /></svg>');
        //     width: 24px; /* Breite des Icons anpassen */
        //     height: 24px;
        //     font-size: 24px; /* Ändern Sie die Schriftgröße des Icons nach Bedarf */
        //     fill: rgba(0, 0, 0, 0.209); /* Ändern Sie die Farbe des Icons nach Bedarf */
        //     position: absolute;
        //     top: -30px;
        //     right: -30px;
        //     transform: translateX(-50%);
        //     z-index: 2; /* Stellen Sie sicher, dass das Icon über dem Element liegt */
        // }

        a {
            font-weight: bold;
            text-decoration: none;
        }
        table {
            // .grip-column {
            //     display: none;
            // }
            // .has-focus {
            //     display: grid !important;
            // }
            border-collapse: collapse;
            table-layout: fixed;
            width: 100%;
            margin: 0;
            overflow: hidden;

            td,
            th {
                min-width: 1em;
                border: 2px solid #ced4da;
                padding: 3px 5px;
                vertical-align: top;
                box-sizing: border-box;
                position: relative;

                > * {
                    margin-bottom: 0;
                }
            }

            th {
                font-weight: bold;
                text-align: left;
                background-color: #f1f3f5;
            }

            .selectedCell:after {
                z-index: 2;
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(200, 200, 255, 0.4);
                pointer-events: none;
            }

            .column-resize-handle {
                position: absolute;
                right: -2px;
                top: 0;
                bottom: -2px;
                width: 4px;
                background-color: #adf;
                pointer-events: none;
            }

            p {
                margin: 0;
            }
        }
    }

    .submenu {
        border: 1px solid rgb(229 229 229) !important;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    }
    .tableWrapper {
        overflow-x: auto;
    }
    .menu-vl {
        border-left: 1px solid rgb(229 229 229);
        height: 26px;
    }
    /* Basic editor styles */
    .tiptap {
        > * + * {
            margin-top: 0.75em;
        }
    }
    .ProseMirror > * {
        margin-left: auto;
        margin-right: auto;
        max-width: 42rem;
    }
    .tiptap {
        font-family: Arial, Helvetica, sans-serif;
        min-height: 70vh;
        font-size: 16px;
        color: #171717;
        > p {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }
    input[type="checkbox"] {
        margin-right: 4px;
    }
    .quaro-bubble-menu > div > button {
        min-width: unset !important;
        height: 32px !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        border-radius: 0.375rem;
        text-transform: unset !important;
    }
    .quaro-bubble-menu > div {
        padding-bottom: 15px;
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
        --tw-text-opacity: 1;
        color: rgb(115 115 115 / var(--tw-text-opacity));
        line-height: 1;
        padding: 0.25rem;
        --tw-border-opacity: 1;
        border: 1px solid rgb(229 229 229);
        border-color: rgb(229 229 229);
        border-width: 1px;
        border-radius: 0.5rem;
        gap: 0.125rem;
        align-items: center;
        flex-direction: row;
        height: 100%;
        display: inline-flex;
        --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .menu-list .is-active {
        background-color: #f4f4f4;
    }
    .quaro-bubble-menu .is-active {
        background-color: #f4f4f4;
        .v-icon {
            color: white;
        }
    }
    .ProseMirror {
        z-index: 0;
        padding: 4.5rem 2rem;

        caret-color: #000 !important;
        // outline-width: 3;
        // outline-color: #e1e1e1 !important;
        // border: 3px solid #e1e1e1 !important;
        border-radius: 10px;
        background-color: #fdfdfd;
    }
    .min-h-full {
        min-height: 100%;
    }
    .spinner-animation {
        animation: spinner 1.6s linear infinite;
        animation-delay: -1.6s;
    }

    .spinner-animation-secondary {
        animation-delay: -1s;
    }

    // @keyframes spinner {
    //     12.5 % {
    //         x: 13px;
    //         y: 1px;
    //     }

    //     25% {
    //         x: 13px;
    //         y: 1px;
    //     }

    //     37.5% {
    //         x: 13px;
    //         y: 13px;
    //     }

    //     50% {
    //         x: 13px;
    //         y: 13px;
    //     }

    //     62.5% {
    //         x: 1px;
    //         y: 13px;
    //     }

    //     75% {
    //         x: 1px;
    //         y: 13px;
    //     }

    //     87.5% {
    //         x: 1px;
    //         y: 1px;
    //     }
    // }
    .ProseMirror > * {
        margin-left: auto;
        margin-right: auto;
        max-width: 42rem;
    }
    .ProseMirror figure[data-type="imageBlock"] {
        margin: 0;
    }

    .ProseMirror figure[data-type="imageBlock"] img {
        display: block;
        width: 100%;
        border-radius: 0.25rem;
    }

    .ProseMirror figure[data-type="blockquoteFigure"] {
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;
        --tw-text-opacity: 1;
        color: rgb(0 0 0 / var(--tw-text-opacity));
    }

    :is(.dark .ProseMirror figure[data-type="blockquoteFigure"]) {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
    }

    .ProseMirror [data-type="blockquoteFigure"] blockquote,
    .ProseMirror > blockquote blockquote {
        margin: 0;
    }

    .ProseMirror [data-type="blockquoteFigure"] blockquote > :first-child,
    .ProseMirror > blockquote blockquote > :first-child {
        margin-top: 0;
    }

    .ProseMirror [data-type="blockquoteFigure"] blockquote > :last-child,
    .ProseMirror > blockquote blockquote > :last-child {
        margin-bottom: 0;
    }

    .ProseMirror [data-type="columns"] {
        margin-top: 3.5rem;
        margin-bottom: 3rem;
        display: grid;
        gap: 1rem;
    }

    .ProseMirror [data-type="columns"].layout-sidebar-left {
        grid-template-columns: 40fr 60fr;
    }

    .ProseMirror [data-type="columns"].layout-sidebar-right {
        grid-template-columns: 60fr 40fr;
    }

    .ProseMirror [data-type="columns"].layout-two-column {
        grid-template-columns: 1fr 1fr;
    }

    .ProseMirror [data-type="column"] {
        overflow: auto;
    }

    .ProseMirror code {
        border-radius: 0.125rem;
        --tw-bg-opacity: 1;
        background-color: rgb(23 23 23 / var(--tw-bg-opacity));
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
        caret-color: #fff;
        --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
        --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .ProseMirror code::-moz-selection {
        background-color: hsla(0, 0%, 100%, 0.3);
    }

    .ProseMirror code::selection {
        background-color: hsla(0, 0%, 100%, 0.3);
    }

    .ProseMirror pre {
        margin-top: 3rem;
        margin-bottom: 3rem;
        border-radius: 0.25rem;
        border-width: 1px;
        --tw-border-opacity: 1;
        border-color: rgb(0 0 0 / var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: rgb(64 64 64 / var(--tw-bg-opacity));
        padding: 1rem;
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
        caret-color: #fff;
    }

    :is(.dark .ProseMirror pre) {
        --tw-border-opacity: 1;
        border-color: rgb(38 38 38 / var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: rgb(23 23 23 / var(--tw-bg-opacity));
    }

    .ProseMirror pre ::-moz-selection {
        background-color: hsla(0, 0%, 100%, 0.2);
    }

    .ProseMirror pre ::selection {
        background-color: hsla(0, 0%, 100%, 0.2);
    }

    .ProseMirror pre code {
        background-color: inherit;
        padding: 0;
        color: inherit;
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .ProseMirror pre .hljs-comment,
    .ProseMirror pre .hljs-quote {
        --tw-text-opacity: 1;
        color: rgb(163 163 163 / var(--tw-text-opacity));
    }

    .ProseMirror pre .hljs-attribute,
    .ProseMirror pre .hljs-link,
    .ProseMirror pre .hljs-name,
    .ProseMirror pre .hljs-regexp,
    .ProseMirror pre .hljs-selector-class,
    .ProseMirror pre .hljs-selector-id,
    .ProseMirror pre .hljs-tag,
    .ProseMirror pre .hljs-template-variable,
    .ProseMirror pre .hljs-variable {
        --tw-text-opacity: 1;
        color: rgb(252 165 165 / var(--tw-text-opacity));
    }

    .ProseMirror pre .hljs-built_in,
    .ProseMirror pre .hljs-builtin-name,
    .ProseMirror pre .hljs-literal,
    .ProseMirror pre .hljs-meta,
    .ProseMirror pre .hljs-number,
    .ProseMirror pre .hljs-params,
    .ProseMirror pre .hljs-type {
        --tw-text-opacity: 1;
        color: rgb(253 186 116 / var(--tw-text-opacity));
    }

    .ProseMirror pre .hljs-bullet,
    .ProseMirror pre .hljs-string,
    .ProseMirror pre .hljs-symbol {
        --tw-text-opacity: 1;
        color: rgb(190 242 100 / var(--tw-text-opacity));
    }

    .ProseMirror pre .hljs-section,
    .ProseMirror pre .hljs-title {
        --tw-text-opacity: 1;
        color: rgb(253 224 71 / var(--tw-text-opacity));
    }

    .ProseMirror pre .hljs-keyword,
    .ProseMirror pre .hljs-selector-tag {
        --tw-text-opacity: 1;
        color: rgb(94 234 212 / var(--tw-text-opacity));
    }

    .ProseMirror pre .hljs-emphasis {
        font-style: italic;
    }

    .ProseMirror pre .hljs-strong {
        font-weight: 700;
    }

    .ProseMirror .collaboration-cursor__caret {
        pointer-events: none;
        position: relative;
        margin-left: -1px;
        margin-right: -1px;
        overflow-wrap: normal;
        word-break: normal;
        border-right-width: 1px;
        border-left-width: 1px;
        --tw-border-opacity: 1;
        border-color: rgb(0 0 0 / var(--tw-border-opacity));
    }

    .ProseMirror .collaboration-cursor__label {
        position: absolute;
        left: -1px;
        top: -1.4em;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        white-space: nowrap;
        border-radius: 0.25rem;
        border-top-left-radius: 0;
        padding: 0.125rem 0.375rem;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 600;
        line-height: 1;
        --tw-text-opacity: 1;
        color: rgb(0 0 0 / var(--tw-text-opacity));
    }

    .ProseMirror ol {
        list-style-type: decimal;
    }

    .ProseMirror ul {
        list-style-type: disc;
    }

    .ProseMirror ol,
    .ProseMirror ul {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        padding: 0 2rem;
    }

    .ProseMirror ol:first-child,
    .ProseMirror ul:first-child {
        margin-top: 0;
    }

    .ProseMirror ol:last-child,
    .ProseMirror ul:last-child {
        margin-bottom: 0;
    }

    .ProseMirror ol li,
    .ProseMirror ol ol,
    .ProseMirror ol ul,
    .ProseMirror ul li,
    .ProseMirror ul ol,
    .ProseMirror ul ul {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }

    .ProseMirror ol p,
    .ProseMirror ul p {
        margin-top: 0;
        margin-bottom: 0.25rem;
    }

    .ProseMirror > ol,
    .ProseMirror > ul {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .ProseMirror > ol:first-child,
    .ProseMirror > ul:first-child {
        margin-top: 0;
    }

    .ProseMirror > ol:last-child,
    .ProseMirror > ul:last-child {
        margin-bottom: 0;
    }

    .ProseMirror ul[data-type="taskList"] {
        list-style-type: none;
        padding: 0;
    }

    .ProseMirror ul[data-type="taskList"] p {
        margin: 0;
    }

    .ProseMirror ul[data-type="taskList"] li {
        display: flex;
    }

    .ProseMirror ul[data-type="taskList"] li > label {
        margin-top: 0.25rem;
        margin-right: 0.5rem;
        flex: 1 1 auto;
        flex-shrink: 0;
        flex-grow: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .ProseMirror ul[data-type="taskList"] li > div {
        flex: 1 1 auto;
    }

    .ProseMirror ul[data-type="taskList"] li[data-checked="true"] {
        text-decoration-line: line-through;
    }

    .ProseMirror .is-empty:before {
        pointer-events: none;
        float: left;
        height: 0;
        width: 100%;
        color: rgba(0, 0, 0, 0.4);
    }

    :is(.dark .ProseMirror .is-empty):before {
        color: hsla(0, 0%, 100%, 0.4);
    }

    .ProseMirror.ProseMirror-focused > [data-type="columns"] > [data-type="column"] > p.is-empty.has-focus:before,
    .ProseMirror.ProseMirror-focused > p.has-focus.is-empty:before {
        content: "Type  /  to browse options";
    }

    .ProseMirror > .is-editor-empty:before {
        content: "Click here to start writing …";
    }

    .ProseMirror blockquote .is-empty:not(.is-editor-empty):first-child:last-child:before {
        content: "Enter a quote";
    }

    .ProseMirror blockquote + figcaption.is-empty:not(.is-editor-empty):before {
        content: "Author";
    }

    .ProseMirror [data-placeholder][data-suggestion] :before,
    .ProseMirror [data-placeholder][data-suggestion]:before {
        content: none !important;
    }

    .ProseMirror .node-embedInput.is-empty [data-node-view-content] > :last-child:before {
        pointer-events: none;
        float: left;
        height: 0;
        color: rgba(0, 0, 0, 0.5);
    }

    :is(.dark .ProseMirror .node-embedInput.is-empty [data-node-view-content] > :last-child):before {
        color: hsla(0, 0%, 100%, 0.5);
    }

    .ProseMirror .node-embedInput.is-empty [data-node-view-wrapper] [data-node-view-content] > :last-child:before {
        content: "Paste or type a URL";
    }

    .ProseMirror .node-embedInput.is-empty [data-node-view-wrapper][data-service="twitter"] [data-node-view-content] > :last-child:before {
        content: "Paste or type a Twitter URL";
    }

    .ProseMirror .node-embedInput.is-empty [data-node-view-wrapper][data-service="youtube"] [data-node-view-content] > :last-child:before {
        content: "Paste or type an Instagram URL";
    }

    .ProseMirror .node-embedInput.is-empty [data-node-view-wrapper][data-service="tiktok"] [data-node-view-content] > :last-child:before {
        content: "Paste or type a Figma URL";
    }

    .ProseMirror .node-embedInput.is-empty [data-node-view-wrapper][data-service="framer"] [data-node-view-content] > :last-child:before {
        content: "Paste or type a Framer URL";
    }

    .ProseMirror .node-embedInput.is-empty [data-node-view-wrapper][data-service="figma"] [data-node-view-content] > :last-child:before {
        content: "Paste or type a Tiktok URL";
    }

    .ProseMirror .node-embedInput.is-empty [data-node-view-wrapper][data-service="instagram"] [data-node-view-content] > :last-child:before {
        content: "Paste or type a YouTube URL";
    }

    .ProseMirror .tableWrapper {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .ProseMirror table {
        box-sizing: border-box;
        width: 100%;
        border-collapse: collapse;
        border-radius: 0.25rem;
        border-color: rgba(0, 0, 0, 0.1);
    }

    :is(.dark .ProseMirror table) {
        border-color: hsla(0, 0%, 100%, 0.2);
    }

    .ProseMirror table td,
    .ProseMirror table th {
        position: relative;
        min-width: 100px;
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.1);
        padding: 0.5rem;
        text-align: left;
        vertical-align: top;
    }

    :is(.dark .ProseMirror table td),
    :is(.dark .ProseMirror table th) {
        border-color: hsla(0, 0%, 100%, 0.2);
    }

    .ProseMirror table td:first-of-type:not(a),
    .ProseMirror table th:first-of-type:not(a) {
        margin-top: 0;
    }

    .ProseMirror table td p,
    .ProseMirror table th p {
        margin: 0;
    }

    .ProseMirror table td p + p,
    .ProseMirror table th p + p {
        margin-top: 0.75rem;
    }

    .ProseMirror table th {
        font-weight: 700;
    }

    .ProseMirror table .column-resize-handle {
        pointer-events: none;
        position: absolute;
        bottom: -2px;
        right: -0.25rem;
        top: 0;
        display: flex;
        width: 0.5rem;
    }

    .ProseMirror table .column-resize-handle:before {
        margin-left: 0.5rem;
        height: 100%;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.2);
    }

    :is(.dark .ProseMirror table .column-resize-handle):before {
        background-color: hsla(0, 0%, 100%, 0.2);
    }

    .ProseMirror table .column-resize-handle:before {
        content: "";
    }

    .ProseMirror table .selectedCell {
        border-style: double;
        border-color: rgba(0, 0, 0, 0.2);
        background-color: rgba(0, 0, 0, 0.05);
    }

    :is(.dark .ProseMirror table .selectedCell) {
        border-color: hsla(0, 0%, 100%, 0.2);
        background-color: hsla(0, 0%, 100%, 0.1);
    }

    .ProseMirror table .grip-column,
    .ProseMirror table .grip-row {
        position: absolute;
        z-index: 10;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.05);
    }

    :is(.dark .ProseMirror table .grip-column),
    :is(.dark .ProseMirror table .grip-row) {
        background-color: hsla(0, 0%, 100%, 0.1);
    }

    .ProseMirror table .grip-column {
        left: 0;
        top: -0.75rem;
        margin-left: -1px;
        height: 0.75rem;
        width: calc(100% + 1px);
        border-left-width: 1px;
        border-color: rgba(0, 0, 0, 0.2);
    }

    :is(.dark .ProseMirror table .grip-column) {
        border-color: hsla(0, 0%, 100%, 0.2);
    }

    .ProseMirror table .grip-column.selected:before,
    .ProseMirror table .grip-column:hover:before {
        content: "";
        width: 0.625rem;
    }

    .ProseMirror table .grip-column:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    :is(.dark .ProseMirror table .grip-column:hover) {
        background-color: hsla(0, 0%, 100%, 0.2);
    }

    .ProseMirror table .grip-column:hover:before {
        border-bottom: 2px;
        border-color: rgba(0, 0, 0, 0.6);
        border-style: dotted;
    }

    :is(.dark .ProseMirror table .grip-column:hover):before {
        border-color: hsla(0, 0%, 100%, 0.6);
    }

    .ProseMirror table .grip-column.first {
        border-top-left-radius: 0.125rem;
        border-color: transparent;
    }

    .ProseMirror table .grip-column.last {
        border-top-right-radius: 0.125rem;
    }

    .ProseMirror table .grip-column.selected {
        border-color: rgba(0, 0, 0, 0.3);
        background-color: rgba(0, 0, 0, 0.3);
        --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    :is(.dark .ProseMirror table .grip-column.selected) {
        border-color: hsla(0, 0%, 100%, 0.3);
        background-color: hsla(0, 0%, 100%, 0.3);
    }

    .ProseMirror table .grip-column.selected:before {
        border-bottom-width: 2px;
        border-style: dotted;
    }

    .ProseMirror table .grip-row {
        left: -0.75rem;
        top: 0;
        margin-top: -1px;
        height: calc(100% + 1px);
        width: 0.75rem;
        border-top-width: 1px;
        border-color: rgba(0, 0, 0, 0.2);
    }

    :is(.dark .ProseMirror table .grip-row) {
        border-color: hsla(0, 0%, 100%, 0.2);
    }

    .ProseMirror table .grip-row.selected:before,
    .ProseMirror table .grip-row:hover:before {
        height: 0.625rem;
        content: "";
    }

    .ProseMirror table .grip-row:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    :is(.dark .ProseMirror table .grip-row:hover) {
        background-color: hsla(0, 0%, 100%, 0.2);
    }

    .ProseMirror table .grip-row:hover:before {
        border-left: 2px;
        border-color: rgba(0, 0, 0, 0.6);
        border-style: dotted;
    }

    :is(.dark .ProseMirror table .grip-row:hover):before {
        border-color: hsla(0, 0%, 100%, 0.6);
    }

    .ProseMirror table .grip-row.first {
        border-top-left-radius: 0.125rem;
        border-color: transparent;
    }

    .ProseMirror table .grip-row.last {
        border-bottom-left-radius: 0.125rem;
    }

    .ProseMirror table .grip-row.selected {
        border-color: rgba(0, 0, 0, 0.3);
        background-color: rgba(0, 0, 0, 0.3);
        --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    :is(.dark .ProseMirror table .grip-row.selected) {
        border-color: hsla(0, 0%, 100%, 0.3);
        background-color: hsla(0, 0%, 100%, 0.3);
    }

    .ProseMirror table .grip-row.selected:before {
        border-left-width: 2px;
        border-style: dotted;
    }

    .ProseMirror p {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        line-height: 1.625;
    }

    .ProseMirror p:first-child {
        margin-top: 0;
    }

    .ProseMirror p:last-child {
        margin-bottom: 0;
    }

    .ProseMirror > p {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .ProseMirror > p:first-child {
        margin-top: 0;
    }

    .ProseMirror > p:last-child {
        margin-bottom: 0;
    }

    .ProseMirror h1 {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }

    .ProseMirror h2 {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .ProseMirror h3 {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .ProseMirror h4 {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }

    .ProseMirror h5 {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .ProseMirror h6 {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .ProseMirror h1,
    .ProseMirror h2,
    .ProseMirror h3,
    .ProseMirror h4,
    .ProseMirror h5,
    .ProseMirror h6 {
        font-weight: 700;
    }

    .ProseMirror h1:first-child,
    .ProseMirror h2:first-child,
    .ProseMirror h3:first-child,
    .ProseMirror h4:first-child,
    .ProseMirror h5:first-child,
    .ProseMirror h6:first-child {
        margin-top: 0;
    }

    .ProseMirror h1:last-child,
    .ProseMirror h2:last-child,
    .ProseMirror h3:last-child,
    .ProseMirror h4:last-child,
    .ProseMirror h5:last-child,
    .ProseMirror h6:last-child {
        margin-bottom: 0;
    }

    .ProseMirror h1,
    .ProseMirror h2,
    .ProseMirror h3 {
        margin-top: 3rem;
    }

    .ProseMirror h4,
    .ProseMirror h5,
    .ProseMirror h6 {
        margin-top: 2rem;
    }

    .ProseMirror a.link {
        font-weight: 800;
        --tw-text-opacity: 1;
        color: rgb(59 130 246 / var(--tw-text-opacity));
    }

    :is(.dark .ProseMirror a.link) {
        --tw-text-opacity: 1;
        color: rgb(96 165 250 / var(--tw-text-opacity));
    }

    .ProseMirror mark {
        border-radius: 0.125rem;
        --tw-bg-opacity: 1;
        background-color: rgb(239 68 68 / var(--tw-bg-opacity));
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        padding: 0.25rem 0;
        color: inherit;
    }

    :is(.dark .ProseMirror mark) {
        --tw-bg-opacity: 1;
        background-color: rgb(248 113 113 / var(--tw-bg-opacity));
    }

    .ProseMirror img {
        height: auto;
        // width: 100%;
        max-width: 100%;
    }

    .ProseMirror [data-type="horizontalRule"] {
        margin-top: 2rem;
        margin-bottom: 2rem;
        cursor: pointer;
        padding-top: 1rem;
        padding-bottom: 1rem;
        transition-property: all;
        transition-duration: 0.1s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        animation-duration: 0.1s;
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    .ProseMirror [data-type="horizontalRule"].ProseMirror-selectednode {
        background-color: rgba(0, 0, 0, 0.05);
    }

    :is(.dark .ProseMirror [data-type="horizontalRule"].ProseMirror-selectednode) {
        background-color: hsla(0, 0%, 100%, 0.1);
    }

    .ProseMirror [data-type="horizontalRule"].ProseMirror-selectednode hr {
        border-top-color: rgba(0, 0, 0, 0.3);
    }

    :is(.dark .ProseMirror [data-type="horizontalRule"].ProseMirror-selectednode hr) {
        border-top-color: hsla(0, 0%, 100%, 0.3);
    }

    .ProseMirror [data-type="horizontalRule"]:hover:not(.ProseMirror [data-type="horizontalRule"].ProseMirror-selectednode) {
        background-color: rgba(0, 0, 0, 0.05);
    }

    :is(.dark .ProseMirror [data-type="horizontalRule"]:hover:not(.ProseMirror [data-type="horizontalRule"].ProseMirror-selectednode)) {
        background-color: hsla(0, 0%, 100%, 0.1);
    }

    .ProseMirror [data-type="horizontalRule"] hr {
        border-width: 1px 0 0;
        border-color: rgba(0, 0, 0, 0.2);
        background-color: rgba(0, 0, 0, 0.8);
    }

    :is(.dark .ProseMirror [data-type="horizontalRule"] hr) {
        border-color: hsla(0, 0%, 100%, 0.2);
        background-color: hsla(0, 0%, 100%, 0.8);
    }

    .ProseMirror {
        z-index: 0;
        // padding: 4rem 2rem 4rem 5rem;
        caret-color: #000;
        outline-width: 0;
    }

    :is(.dark .ProseMirror) {
        caret-color: #fff;
    }

    @media (min-width: 1024px) {
        .ProseMirror {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .ProseMirror > img {
        margin-left: auto;
        margin-right: auto;
        max-width: 42rem;
        // width: 100%;
    }

    .ProseMirror .selection {
        display: inline;
    }

    .ProseMirror ::-moz-selection {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .ProseMirror .selection,
    .ProseMirror ::selection {
        background-color: rgba(0, 0, 0, 0.1);
    }

    :is(.dark .ProseMirror *)::-moz-selection {
        background-color: hsla(0, 0%, 100%, 0.2);
    }

    :is(.dark .ProseMirror *)::selection,
    :is(.dark .ProseMirror .selection) {
        background-color: hsla(0, 0%, 100%, 0.2);
    }

    .ProseMirror > .react-renderer {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .ProseMirror > .react-renderer:first-child {
        margin-top: 0;
    }

    .ProseMirror > .react-renderer:last-child {
        margin-bottom: 0;
    }

    .ProseMirror.resize-cursor {
        cursor: col-resize;
    }

    .ProseMirror .ProseMirror-gapcursor {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 42rem;
    }

    .ProseMirror .ProseMirror-gapcursor:after {
        // top: -1.5em;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 42rem;
        border-top-color: rgba(0, 0, 0, 0.4);
    }

    :is(.dark .ProseMirror .ProseMirror-gapcursor):after {
        border-top-color: hsla(0, 0%, 100%, 0.4);
    }

    [data-theme="slash-command"] {
        width: 1000vw;
    }
</style>
